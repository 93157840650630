import React from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
    makeStyles,
    Breadcrumbs,
    Paper,
    Typography,
    TableRow,
    TablePagination,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableFooter,
    Divider,
    Button,
} from '@material-ui/core';
import { getApiTokenSelector } from "../../state/Auth"
import { getCustomersMapSelector } from "../../state/Customers"
import {
    getCachedCustomerIDSelector,
    getCachedCustomerFacilitiesMapSelector,
    loadCustomer,
} from "../../state/CachedCustomer"
import {
    getCachedFacilityIDSelector,
    getCachedFacilityTagsSelector,
    loadFacility,
} from "../../state/CachedFacility"
import { getExercisesListSelector } from "../../state/Exercises"
import AppConfiguration from "../../config/Config.json"
import ApiCall from "../../api/ApiCall.js"
import TagsApi from "../../api/Tags.js"

const tagsURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Tags
const tagsApi = new TagsApi(tagsURL)

const createTag = (apiToken, facilityID, exerciseID, name, onError, onSuccess) => {
    return dispatch => {
        ApiCall(dispatch, tagsApi.createOne)(apiToken, facilityID, exerciseID, name)
            .then(onSuccess)
            .catch(onError)
    }
}

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    headingRow: {
        display: "flex",
        flexDirection: "row",
        paddingRight: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    table: {
        overflowY: "auto",
        [theme.breakpoints.down('xs')]: {
            maxHeight: theme.spacing(35),
            minWidth: theme.spacing(35),
        },
        [theme.breakpoints.up('sm')]: {
            maxHeight: theme.spacing(55),
            minWidth: theme.spacing(65),
        },
    },
}))

const Nav = props => {

    const {customer, facility} = props

    return (
        <Breadcrumbs separator=">" aria-label="breadcrumbs">
            <Link color="inherit" to="/customers">Customers</Link>

            {customer &&
            <Link color="inherit" to={`/customers/${customer.id}`}>{customer.name}</Link>
            }

            {facility &&
            <Link
                color="inherit"
                to={`/customers/${customer.id}/facilities/${facility.id}`}>
                    {facility.name}
            </Link>
            }
        </Breadcrumbs>
    )
}

const CreateTags = props => {

    const {
        apiToken,
        customers, cachedCustomerID, loadCustomer,
        facilities, cachedFacilityID, loadFacility,
        exercises, createTag,
    } = props
    const {customerID, facilityID} = useParams()
    const customer = customers[customerID]
    const facility = facilities[facilityID]

    const classes = useStyles();
    const [offset, setOffset] = React.useState(0)
    const [disabled, setDisabled] = React.useState(false)

    const limit = 10
    const count = exercises.length
    const pageEnd = offset+limit < count ? offset+limit : count
    const data = exercises.slice(offset, pageEnd)

    React.useEffect(() => {
        if(customerID !== cachedCustomerID) {
            loadCustomer(apiToken, customerID, error => console.error(error))
        }
    }, [apiToken, customerID, cachedCustomerID, loadCustomer])

    React.useEffect(() => {
        if (facilityID !== cachedFacilityID) {
            loadFacility(apiToken, facilityID, error => console.error(error))
        }
    }, [apiToken, facilityID, cachedFacilityID, loadFacility])

    const onCreate = (name, exerciseID) => {
        setDisabled(true)
        createTag(apiToken, facilityID, exerciseID, name, error => {
            if(error?.message === "Unauthorized") {
                // do nothing. user will get logged out
                return
            }
            setDisabled(false)
            console.error(error)
        }, () => {
            setDisabled(false)
            loadFacility(apiToken, facilityID, error => console.error(error))
        })
    }

    if(cachedFacilityID !== facility?.id) {
        return (
            <Paper elevation={6} className={classes.main}>
                <Nav customer={customer} facility={facility} />
                <div className={classes.section}>
                    <Typography variant="h4">Facility</Typography>
                </div>
                <div className={classes.section}>
                    <Typography variant="h6">Loading...</Typography>
                </div>
            </Paper>
        )
    }

    const paginationRow = (
        <TableRow>
            <TablePagination
             rowsPerPage={limit}
             rowsPerPageOptions={[]}
             count={count}
             page={offset/limit}
             onChangePage={(e,page)=>{setOffset(page*limit)}}
            />
        </TableRow>
    )

    return (
        <Paper elevation={6} className={classes.main}>
            <Nav customer={customer} facility={facility} />
            <div className={classes.section}>
                <Typography variant="h4">Exercises</Typography>
            </div>
            <Divider />
            <div className={classes.section}>
                <Typography variant="h6">Exercises</Typography>
            </div>
            <Divider />
            <div className={classes.section}>
                <div className={classes.table}>
                    <Table>
                        {count > limit && <TableHead>{paginationRow}</TableHead>}
                        <TableBody>
                            {data.map(exercise => (
                                <TableRow key={exercise.id}>
                                    <TableCell>
                                        <Link to={`/exercises/${exercise.id}`}>{exercise.name}</Link>
                                    </TableCell>
                                    <TableCell>
                                        {exercise.tag && 
                                        <Link to={`/customers/${customerID}/facilities/${facilityID}/tags/${exercise.tag.id}`}>
                                            View Tag
                                        </Link>
                                        }
                                        {!exercise.tag &&
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            id="create-tag"
                                            disabled={disabled}
                                            onClick={() => onCreate(exercise.name, exercise.id)}
                                        >Tag</Button>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        {count > limit && <TableFooter>{paginationRow}</TableFooter>}
                    </Table>
                </div>
            </div>
        </Paper>
    )
}

const mapStateToProps = state => {

    const apiToken = getApiTokenSelector(state)
    const customers = getCustomersMapSelector(state)
    const cachedCustomerID = getCachedCustomerIDSelector(state)
    const facilities = getCachedCustomerFacilitiesMapSelector(state)
    const cachedFacilityID = getCachedFacilityIDSelector(state)
    const tagsList = getCachedFacilityTagsSelector(state)
    const exercisesList = getExercisesListSelector(state)

    const tags = {}
    tagsList.forEach(tag => {
        tags[tag.exerciseID] = tag
    })

    const exercises = []
    for(const exercise of exercisesList) {
        const tag = tags[exercise.id]
        exercises.push({...exercise, tag: tag,})
    }

    return {apiToken, customers, cachedCustomerID, facilities, cachedFacilityID, exercises}
}

export default connect(mapStateToProps, {loadCustomer, loadFacility, createTag})(CreateTags)