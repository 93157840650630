import {
    STATUS_UNINITIALIZED,
    STATUS_LOADING,
    STATUS_ERROR,
    STATUS_LOADED,
    LOAD_DATA_FOR_CUSTOMER,
    SET_ERROR,
    SET_DATA_FOR_CUSTOMER,
} from "../CachedCustomer";
import { LOGOUT } from "../Auth";

const initialState = {loadStatus : STATUS_UNINITIALIZED}

export default function(state=initialState, action) {
    switch(action.type) {
        case LOAD_DATA_FOR_CUSTOMER: {
            return {loadStatus: STATUS_LOADING, id: action.payload}
        }
        case SET_ERROR : {
            return {...state, loadStatus: STATUS_ERROR, error: action.payload}
        }
        case SET_DATA_FOR_CUSTOMER: {
            return {...state, loadStatus: STATUS_LOADED, facilities: action.payload}
        }
        case LOGOUT : {
            return initialState
        }
        default:
            return state
    }
}