export default class TokensApi {

    constructor(apiUrl) {
         this.apiUrl = apiUrl;
    }

    createOne = async (username, password) => {

        const request = {
            Username : username,
            Password : password,
        }

        return await fetch(
            this.apiUrl,
            {
                method: 'post',
                body: JSON.stringify(request)
            },
        )

    }

    deleteOne = async (apiToken, username) => {

        if(!username || !apiToken) {
            throw Error("username required to delete token")
        }

        return await fetch(
            `${this.apiUrl}?username=${username}`,
            {
                method: 'delete',
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken
                })
            },
        )

    }

}