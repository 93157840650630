export default class ExercisesApi {

    constructor(apiUrl) {
         this.apiUrl = apiUrl;
    }

    createOne = async (apiToken, exerciseID, newMedia) => {

        if(!apiToken || !exerciseID || !newMedia?.Type || !newMedia?.Data) {
            throw Error("apiToken, exercise ID, and media Type and Data required to create media")
        }

        return await fetch(
            `${this.apiUrl}?exerciseID=${exerciseID}`,
            {
                method: 'post',
                body: JSON.stringify(newMedia),
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )
    }

    updateOne = async (apiToken, media) => {

        if(!apiToken || !media?.ID || !media.Type || !media?.Data ) {
            throw Error("apiToken and media ID, Type and Data required to update media")
        }

        return await fetch(
            `${this.apiUrl}/${media.ID}`,
            {
                method: 'put',
                body: JSON.stringify({
                    Type: media.Type,
                    Data: media.Data,
                    Primary: media.Primary,
                }),
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )
    }

}