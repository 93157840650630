import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles, TextField, Button, Paper, Typography, Divider, Breadcrumbs, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { getApiTokenSelector } from "../../state/Auth"
import { createExercise } from "../../state/Exercises"
import AppConfiguration from "../../config/Config.json"
import ApiCall from '../../api/ApiCall';
import MediaApi from "../../api/Media.js"

const mediaURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Media
const mediaApi = new MediaApi(mediaURL)

const createMedia = (apiToken, exerciseID, mediaData, onError, onSuccess) => {
    return dispatch => {
        ApiCall(dispatch, mediaApi.createOne)
            (apiToken, exerciseID, {
                Type: "YOUTUBE",
                Data: mediaData,
                Primary: true,
            }).then(onSuccess).catch(onError)
    }
}

// Form States
const EDITING = "EDITING"
const CREATING = "CREATING"
const CREATED = "CREATED"

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}))

const Nav = () => {
    return (
        <Breadcrumbs separator=">" aria-label="breadcrumbs">
            <Link color="inherit" to="/exercises">Exercises</Link>
        </Breadcrumbs>
    )
}

const NewExercise = (props) => {

    // inputs
    const {apiToken, createExercise, createMedia} = props

    // state
    const classes = useStyles();
    const [name, setName] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [type, setType] = React.useState("")
    const [youtubeVideoID, setYoutubeVideoID] = React.useState("")
    const [error, setError] = React.useState("")
    const [formState, setFormState] = React.useState(EDITING)
    const [message, setMessage] = React.useState("")
    const disableSubmit = (formState !== EDITING || name.length === 0 || description.length === 0 || type.length === 0 || youtubeVideoID.length === 0)

    // event handlers
    const onInputChange = setter => e => {
        if(error.length !== 0) {
            setError("")
        }
        setter(e.target.value)
    }

    const onCreateMediaResponse = error => {
        setFormState(CREATED)
        if(error){
            setError(`Exericse created. Error on Media (${error.message})`)
        } else {
            setMessage("Exercise and Media created successfully!")
        }
    }

    const onExerciseCreated = exercise => {
        createMedia(apiToken, exercise.ID, youtubeVideoID, error => onCreateMediaResponse(error), () => onCreateMediaResponse())
    }

    const onSubmit = () => {
        setError("")
        createExercise(
            apiToken,
            {name: name, description: description, type: type},
            error => {
                if(error?.message === "Unauthorized") {
                    // do nothing. user will get logged out
                    return
                }
                setFormState(EDITING)
                setError(error.message)
            },
            exercise => {
                onExerciseCreated(exercise)
            },
        )
    }

    // JSX
    return (
        <Paper elevation={6} className={classes.main}>
            <Nav />
            <div className={classes.section}>
                <Typography variant="h4">
                    New Exercise
                </Typography>
            </div>
            <Divider />
            <div className={classes.section}>
                <form
                    onSubmit={e=>{
                        e.preventDefault();
                        setFormState(CREATING)
                        onSubmit()
                    }}
                    noValidate
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        value={name}
                        error={error.length === 0 ? false : true}
                        onChange={onInputChange(setName)}
                        disabled={formState === EDITING ? false : true}
                        autoFocus
                        />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        autoComplete="description"
                        value={description}
                        error={error.length === 0 ? false : true}
                        onChange={onInputChange(setDescription)}
                        disabled={formState === EDITING ? false : true}
                        />
                    <FormControl required variant="outlined" fullWidth>
                        <InputLabel id="type-label">Type</InputLabel>
                        <Select
                            margin="normal"
                            fullWidth
                            id="type"
                            name="type"
                            labelId="type-label"
                            value={type}
                            onChange={onInputChange(setType)}
                            disabled={formState === EDITING ? false : true}
                        >
                            <MenuItem value=""><em>Choose exercise type</em></MenuItem>
                            <MenuItem value="WEIGHT">WEIGHT</MenuItem>
                            <MenuItem value="CARDIO">CARDIO</MenuItem>
                            <MenuItem value="REPS_ONLY">REPS_ONLY</MenuItem>
                            <MenuItem value="TIME_ONLY">TIME_ONLY</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="youtubeVideoID"
                        label="Youtube Video ID"
                        name="youtubeVideoID"
                        autoComplete="youtubeVideoID"
                        value={youtubeVideoID}
                        error={error.length === 0 ? false : true}
                        helperText={error || message}
                        onChange={onInputChange(setYoutubeVideoID)}
                        disabled={formState === EDITING ? false : true}
                        />
                    {formState !== CREATED &&
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={disableSubmit}
                        className={classes.submit}
                        id="create-customer"
                    >Create</Button>
                    }
                    {formState === CREATED &&
                    <Button
                        fullWidth
                        type="button"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        id="create-another"
                        onClick={() => {
                            setName("")
                            setDescription("")
                            setYoutubeVideoID("")
                            setError("")
                            setMessage("")
                            setFormState(EDITING)
                        }}
                    >Reset Form</Button>
                    }
                </form>
            </div>
        </Paper>
    )
}

// Redux connect
const mapStateToProps = state => {
    const apiToken = getApiTokenSelector(state)
    return {apiToken}
}
export default connect(mapStateToProps, {createExercise, createMedia})(NewExercise)