import React from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { makeStyles, TextField, Button, Paper, Typography, Divider, Breadcrumbs } from '@material-ui/core';
import { getApiTokenSelector } from "../../state/Auth"
import { getCustomersMapSelector } from "../../state/Customers"
import { createFacility } from "../../state/CachedCustomer"

// Form States
const EDITING = "EDITING"
const CREATING = "CREATING"
const CREATED = "CREATED"

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}))

const Nav = props => {

    const {customer} = props

    return (
        <Breadcrumbs separator=">" aria-label="breadcrumbs">
            <Link color="inherit" to="/customers">Customers</Link>
            {customer && <Link color="inherit" to={`/customers/${customer.id}`}>{customer.name}</Link>}
        </Breadcrumbs>
    )
}

const NewFacility = (props) => {

    // inputs
    const {apiToken, customers, createFacility} = props
    const {customerID} = useParams()
    const customer = customers[customerID]

    // state
    const classes = useStyles();
    const [name, setName] = React.useState("")
    const [description, setDescription] = React.useState("")
    const [username, setUsername] = React.useState("")
    const [error, setError] = React.useState("")
    const [formState, setFormState] = React.useState(EDITING)
    const [message, setMessage] = React.useState("")
    const disableSubmit = (formState !== EDITING || name.length === 0 || username.length === 0)

    // event handlers
    const onInputChange = setter => e => {
        if(error.length !== 0) {
            setError("")
        }
        setter(e.target.value)
    }

    const onSubmit = () => {
        createFacility(
            apiToken,
            customerID,
            {name: name, description: description},
            username,
            error => {
                if(error?.message === "Unauthorized") {
                    // do nothing. user will get logged out
                    return
                }
                setFormState(EDITING)
                setError(error.message)
            },
            () => {
                setFormState(CREATED)
                setMessage("Facility created successfully!")
            },
        )
    }

    // JSX
    if(customerID !== customer?.id) {
        return (
            <Paper elevation={6} className={classes.main}>
                <Nav />
                <div className={classes.section}>
                    <Typography variant="h4">New Facility</Typography>
                </div>
                <div className={classes.section}>
                    <Typography variant="h6">Loading...</Typography>
                </div>
            </Paper>
        )
    }

    return (
        <Paper elevation={6} className={classes.main}>
            <Nav customer={customer} />
            <div className={classes.section}>
                <Typography variant="h4">
                    {customer.name}
                </Typography>
            </div>
            <Divider />
            <div className={classes.section}>
                <Typography variant="h6">New Facility</Typography>
                <form
                    onSubmit={e=>{
                        e.preventDefault();
                        setFormState(CREATING)
                        onSubmit()
                    }}
                    noValidate
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Name"
                        name="name"
                        autoComplete="name"
                        value={name}
                        error={error.length === 0 ? false : true}
                        onChange={onInputChange(setName)}
                        disabled={formState === EDITING ? false : true}
                        autoFocus
                        />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        autoComplete="description"
                        value={description}
                        error={error.length === 0 ? false : true}
                        onChange={onInputChange(setDescription)}
                        disabled={formState === EDITING ? false : true}
                        />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoComplete="username"
                        value={username}
                        error={error.length === 0 ? false : true}
                        helperText={error || message}
                        onChange={onInputChange(setUsername)}
                        disabled={formState === EDITING ? false : true}
                        />
                    {formState !== CREATED &&
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={disableSubmit}
                        className={classes.submit}
                        id="create-facility"
                    >Create</Button>
                    }
                    {formState === CREATED &&
                    <Button
                        fullWidth
                        type="button"
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        id="create-another"
                        onClick={() => {
                            setName("")
                            setDescription("")
                            setUsername("")
                            setError("")
                            setMessage("")
                            setFormState(EDITING)
                        }}
                    >Reset Form</Button>
                    }
                </form>
            </div>
        </Paper>
    )
}

// Redux connect
const mapStateToProps = state => {
    const apiToken = getApiTokenSelector(state)
    const customers = getCustomersMapSelector(state)
    return {apiToken, customers}
}
export default connect(mapStateToProps, {createFacility})(NewFacility)