import React from 'react';
import { connect } from 'react-redux';
import {
    Paper,
    Grid,
    Typography,
    makeStyles,
    Divider,
    Button,
    Breadcrumbs
} from '@material-ui/core';
import { getApiTokenSelector } from "../../state/Auth"
import { getCustomersMapSelector } from "../../state/Customers"
import {
    getCachedCustomerIDSelector,
    getCachedCustomerFacilitiesMapSelector,
    loadCustomer,
} from "../../state/CachedCustomer"
import ChangePasswordForm from "./ChangePasswordForm"
import AppConfiguration from "../../config/Config.json"
import TokensApi from "../../api/Tokens.js"
import ApiCall from "../../api/ApiCall.js"
import { Link, useParams } from 'react-router-dom';

const tokensURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Tokens
const tokensApi = new TokensApi(tokensURL)

const deleteUserToken = (apiToken, username, onError, onSuccess) => {
    return dispatch => {
        ApiCall(dispatch, tokensApi.deleteOne)
            (apiToken, username).then(() => {
                if(onSuccess) onSuccess()
            }).catch(onError)
    }
}

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    infoRow: {
        marginBottom: theme.spacing(1)
    },
    label: {
        fontWeight: "700",
    },
    button: {
      margin: theme.spacing(3, 0, 2),
    },
}))

const Nav = props => {

    const {customer, facility} = props

    return (
        <Breadcrumbs separator=">" aria-label="breadcrumbs">
            <Link color="inherit" to="/customers">Customers</Link>

            {customer &&
            <Link color="inherit" to={`/customers/${customer.id}`}>{customer.name}</Link>
            }

            {facility &&
            <Link
                color="inherit"
                to={`/customers/${customer.id}/facilities/${facility.id}`}>
                    {facility.name}
            </Link>
            }
        </Breadcrumbs>
    )
}

const User = (props) => {

    const { 
        apiToken, customers, cachedCustomerID, facilities, loadCustomer, deleteUserToken
    } = props
    const {customerID, facilityID, username} = useParams()
    const customer = customers[customerID]
    const facility = facilities[facilityID]

    const classes = useStyles();
    const [error, setError] = React.useState("")
    const [message, setMessage] = React.useState("")

    React.useEffect(() => {
        if(customerID !== cachedCustomerID) {
            loadCustomer(apiToken, customerID, error => console.error(error))
        }
    }, [apiToken, customerID, cachedCustomerID, loadCustomer])

    // JSX
    if((customerID !== customer?.id) || (facilityID !== facility?.id)) {
        return (
            <Paper elevation={6} className={classes.main}>
                <Nav customer={customer} facility={facility} />
                <div className={classes.section}>
                    <Typography variant="h4">User</Typography>
                </div>
                <div className={classes.section}>
                    <Typography variant="h6">Loading...</Typography>
                </div>
            </Paper>
        )
    }

    const onDelete = () => {
        deleteUserToken(apiToken, username, error => {
            if(error?.message === "Unauthorized") {
                // do nothing. user will get logged out
                return
            }
            setMessage("")
            setError(error.message)
        }, () => {
            setError("")
            setMessage("User token deleted!")
        })
    }

    console.log(error, message)

    return(
        <Paper elevation={6} className={classes.main}>
        <Nav customer={customer} facility={facility} />
            <div className={classes.section}>
                <Typography variant="h4">
                    User Settings
                </Typography>
            </div>
            <Divider />
            <div className={classes.section}>
                <Grid container className={classes.infoRow}>
                    <Grid item xs={6}>
                        <Typography className={classes.label}>Username</Typography>
                    </Grid>
                    <Grid item xs={6}><Typography>{username}</Typography></Grid>
                </Grid>
                <Grid container className={classes.infoRow}>
                    <Grid item xs={6}>
                        <Typography className={classes.label}>Organization</Typography>
                    </Grid>
                    <Grid item xs={6}><Typography>{customer?.name}</Typography></Grid>
                </Grid>
            </div>
            <Divider />
            <div className={classes.section}>
                <Typography variant="h6">Change Password</Typography>
                <ChangePasswordForm apiToken={apiToken} username={username} />
            </div>
            <Divider />
            <div className={classes.section}>
                <Typography variant="h6">Delete Token</Typography>
                <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                    id="delete-user-token"
                    onClick={onDelete}
                >Delete</Button>
                <span>{error || message}</span>
            </div>
        </Paper>
    )
}

const mapStateToProps = state => {
    const apiToken = getApiTokenSelector(state)
    const customers = getCustomersMapSelector(state)
    const cachedCustomerID = getCachedCustomerIDSelector(state)
    const facilities = getCachedCustomerFacilitiesMapSelector(state)
    return {apiToken, customers, cachedCustomerID, facilities}
}

export default connect(mapStateToProps, {loadCustomer, deleteUserToken})(User)