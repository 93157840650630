import React from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { 
    Paper,
    makeStyles,
    Breadcrumbs,
    Typography,
    Grid,
    Divider,
} from '@material-ui/core';
import { getApiTokenSelector } from "../../state/Auth.js"
import {
    getCachedFacilityLoadStatusSelector,
    getCachedFacilityIDSelector,
    getCachedFacilityTagsSelector,
    loadFacility,
    STATUS_LOADED,
} from "../../state/CachedFacility"
import { getUserFacilitiesMapSelector } from "../../state/UserFacilities.js"

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    infoRow: {
        marginBottom: theme.spacing(1)
    },
    label: {
        fontWeight: "700",
    },
}))

const Nav = props => {

    const {facility} = props

    return (
        <Breadcrumbs separator=">" aria-label="breadcrumbs">
            <Link color="inherit" to="/">Home</Link>
            {facility && <Link color="inherit" to={`/facilities/${facility.id}`}>{facility.name}</Link>}
        </Breadcrumbs>
    )
}

const Tag = props => {

    const {apiToken, facilities, cacheStatus, cachedFacilityID, cachedTags, loadFacility} = props
    const {facilityID, tagID} = useParams()

    const classes = useStyles();

    const facility = facilities[facilityID]

    React.useEffect(() => {
        if(cachedFacilityID !== facilityID) {
            loadFacility(apiToken, facilityID).catch(error => console.error(error))
        }
    }, [apiToken, cachedFacilityID, facilityID, loadFacility])

    let tag = null
    if(facilityID === cachedFacilityID && cacheStatus === STATUS_LOADED) {
        for(const cachedTag of cachedTags) {
            if(cachedTag.id === tagID) {
                tag = cachedTag
                break
            }
        }
    }

    if(tag === null) {
        return (
            <Paper elevation={6} className={classes.main}>
                <Nav />
                <div className={classes.section}>
                    <Typography variant="h4">Tag</Typography>
                </div>
                <div className={classes.section}>
                    <Typography variant="h6">Loading...</Typography>
                </div>
            </Paper>
        )
    }

    return (
    <Paper elevation={6} className={classes.main}>
        <Nav facility={facility} />
        <div className={classes.section}>
            <Typography variant="h4">{tag.name}</Typography>
        </div>
        <div className={classes.section} style={{flexDirection: "row", justifyContent: "center"}}>
            <QRCode value={`g.chvl.net/${tag.id}`} />
        </div>
        <Divider />
        <div className={classes.section}>
            <Typography variant="h6">Exercise</Typography>
            <Grid container className={classes.infoRow}>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Name</Typography>
                </Grid>
                <Grid item xs={6}><Typography>{tag.exercises[0].name}</Typography></Grid>
            </Grid>
            <Grid container className={classes.infoRow}>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Description</Typography>
                </Grid>
                <Grid item xs={6}><Typography>{tag.exercises[0].description}</Typography></Grid>
            </Grid>
            <Grid container className={classes.infoRow}>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Type</Typography>
                </Grid>
                <Grid item xs={6}><Typography>{tag.exercises[0].type}</Typography></Grid>
            </Grid>
        </div>
        <Divider />
        <div className={classes.section}>
            <Typography variant="h6">Media</Typography>
            {tag.exercises[0].primaryMedia &&
            <a href={`https://www.youtube.com/watch?v=${tag.exercises[0].primaryMedia.data}`}>YouTube</a>
            }
            {!tag.exercises[0].primaryMedia && <Typography>No Media</Typography>}
        </div>
    </Paper>
    )
}

const mapStateToProps = state => {
    const apiToken = getApiTokenSelector(state)
    const facilities = getUserFacilitiesMapSelector(state)
    const cacheStatus = getCachedFacilityLoadStatusSelector(state)
    const cachedFacilityID = getCachedFacilityIDSelector(state)
    const cachedTags = getCachedFacilityTagsSelector(state)

    return {apiToken, facilities, cacheStatus, cachedFacilityID, cachedTags}
}

export default connect(mapStateToProps, {loadFacility})(Tag)
