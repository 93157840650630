import React from 'react';
import { connect } from 'react-redux';
import './App.css';
import { LoginView, MainView } from './ui';
import { isUserLoggedInSelector } from './state/Auth';

function App({isUserLoggedIn}) {
  return isUserLoggedIn ? (<MainView />) : (<LoginView />);
}

const mapStateToProps = state => {
  const isUserLoggedIn = isUserLoggedInSelector(state)
  return {isUserLoggedIn}
}

export default connect(mapStateToProps)(App);
