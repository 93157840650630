import { isUserLoggedInSelector, getLoggedInUserSelector } from "../Auth"
import { areCustomersMissingSelector, loadCustomers } from "../Customers"

const shouldRun = state => {
    if(!isUserLoggedInSelector(state)) return false
    const user = getLoggedInUserSelector(state)
    return (user.isSuperAdmin) && areCustomersMissingSelector(state)
}

const run = (state, dispatch) => {
    const user = getLoggedInUserSelector(state)
    const {apiToken} = user
    const loadCustomersWithDispatch = loadCustomers(
        apiToken, error => console.error(error)
    )
    loadCustomersWithDispatch(dispatch)
}

export default {shouldRun, run}