import React from 'react';
import { connect } from 'react-redux';
import {
    Paper,
    Typography,
    Divider,
    makeStyles,
    Tooltip,
    IconButton,
    Breadcrumbs,
    TextField,
    Grid,
} from '@material-ui/core';
import { getApiTokenSelector } from "../../state/Auth"
import { updateExercise } from "../../state/Exercises"
import AppConfiguration from "../../config/Config.json"
import ApiCall from '../../api/ApiCall'
import ExercisesApi from "../../api/Exercises"
import MediaApi from "../../api/Media"
import { useParams, Link } from 'react-router-dom';
import { Save, Cancel, Edit } from '@material-ui/icons';

const exercisesURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Exercises
const exercisesApi = new ExercisesApi(exercisesURL)

const mediaURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Media
const mediaApi = new MediaApi(mediaURL)

const fetchExercise = (apiToken, exerciseID, onError, onSuccess) => {
    return dispatch => {
        ApiCall(dispatch, exercisesApi.getOne)
            (apiToken, exerciseID).then(onSuccess).catch(onError)
    }
}

const createMedia = (apiToken, exerciseID, mediaData, onError, onSuccess) => {
    return dispatch => {
        ApiCall(dispatch, mediaApi.createOne)
            (apiToken, exerciseID, {
                Type: "YOUTUBE",
                Data: mediaData,
                Primary: true,
            }).then(onSuccess).catch(onError)
    }
}

const updateMedia = (apiToken, mediaID, mediaData, onError, onSuccess) => {
    return dispatch => {
        ApiCall(dispatch, mediaApi.updateOne)
            (apiToken, { 
                ID: mediaID, Type: "YOUTUBE", Data: mediaData, Primary: true, 
            }).then(onSuccess).catch(onError)
    }
}

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    editableRow: {
        display: "flex",
        flexDirection: "row",
        paddingRight: theme.spacing(2),
    },
    label: {
        fontWeight: "700",
    },
}))

const Nav = () => {
    return (
        <Breadcrumbs separator=">" aria-label="breadcrumbs">
            <Link color="inherit" to="/exercises">Exercises</Link>
        </Breadcrumbs>
    )
}

const EditableRow = props => {

    const { field, value, disabled, onSave } = props

    const classes = useStyles();
    const [newValue, setNewValue] = React.useState("")
    const [editing, setEditing] = React.useState(false)

    React.useEffect(() => {
        setNewValue(value)
    }, [value, setNewValue])

    const update = () => {

        setEditing(false)

        if(value === newValue) {
            return
        }

        if(!newValue) {
            setNewValue(value)
            return
        }

        onSave(newValue)

    }

    return editing ? (
        <div className={classes.editableRow}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                id={field.toLowerCase()}
                label={field}
                name={field.toLowerCase()}
                autoComplete={field.toLowerCase()}
                value={newValue}
                disabled={disabled}
                onChange={e => setNewValue(e.target.value)}
                autoFocus
                />
            <Tooltip title="Update"><span>
                <IconButton disabled={disabled} onClick={update}>
                    <Save />
                </IconButton>
            </span></Tooltip>
            <Tooltip title="Cancel"><span>
                <IconButton disabled={disabled} onClick={() => {
                    setNewValue(value)
                    setEditing(false)
                }}>
                    <Cancel />
                </IconButton>
            </span></Tooltip>
        </div>
    ) : (
        <Grid container className={classes.infoRow}>
            <Grid item xs={1}>
                <Tooltip title="Edit"><span>
                    <IconButton size="small" disabled={disabled} onClick={() => {setEditing(true)}}>
                        <Edit />
                    </IconButton>
                </span></Tooltip>
            </Grid>
            <Grid item xs={4}><Typography className={classes.label}>{field}</Typography></Grid>
            <Grid item xs={7}><Typography>{value}</Typography></Grid>
        </Grid>
    )
}

const Exercise = props => {

    const {apiToken, fetchExercise, updateExercise, createMedia, updateMedia} = props
    const {exerciseID} = useParams()

    const classes = useStyles();
    const [exercise, setExercise] = React.useState({})
    const [disabled, setDisabled] = React.useState(false)
    const [message, setMessage] = React.useState("")

    React.useEffect(() => {
        fetchExercise(apiToken, exerciseID, error => console.error(error), exercise => setExercise(exercise))
    }, [apiToken, exerciseID, fetchExercise])

    const onExerciseUpdate = (name, description, type) => {
        setDisabled(true)
        updateExercise(apiToken, {id: exercise.ID, name: name, description: description, type: type}, error => {
            if(error?.message === "Unauthorized") {
                // do nothing. user will get logged out
                return
            }
            setMessage(error?.message)
            setDisabled(false)
        }, updated => {
            setExercise({...exercise, Name : updated.Name, Description: updated.Description, Type: updated.Type})
            setDisabled(false)
        })
    }

    const onMediaUpdate = (mediaData) => {
        setDisabled(true)
        if(exercise.Media && exercise.Media.length > 0) {
            updateMedia(apiToken, exercise.Media[0].ID, mediaData, error => {
                setMessage(error?.message)
                setDisabled(false)
            }, () => {
                setDisabled(false)
                fetchExercise(apiToken, exerciseID, error => console.error(error), exercise => setExercise(exercise))
            })
        } else {
            createMedia(apiToken, exercise.ID, mediaData, error => {
                setMessage(error?.message)
                setDisabled(false)
            }, () => {
                setDisabled(false)
                fetchExercise(apiToken, exerciseID, error => console.error(error), exercise => setExercise(exercise))
            })
        }
    }

    if(exerciseID !== exercise?.ID) {
        return (
            <Paper elevation={6} className={classes.main}>
                <Nav />
                <div className={classes.section}>
                    <Typography variant="h4">Exercise</Typography>
                </div>
                <div className={classes.section}>
                    <Typography variant="h6">Loading...</Typography>
                </div>
            </Paper>
        )
    }

    return (
        <Paper elevation={6} className={classes.main}>
            <Nav />
            <div className={classes.section}>
                <Typography variant="h4">{exercise.Name}</Typography>
            </div>
            <Divider />
            <div className={classes.section}>
                <EditableRow
                    field="Name"
                    value={exercise.Name}
                    disabled={disabled}
                    onSave={newName => onExerciseUpdate(newName, exercise.Description, exercise.Type)} />
                <EditableRow
                    field="Description"
                    value={exercise.Description}
                    disabled={disabled}
                    onSave={newDescription => onExerciseUpdate(exercise.Name, newDescription, exercise.Type)} />
                <EditableRow
                    field="Type"
                    value={exercise.Type}
                    disabled={disabled}
                    onSave={newType => onExerciseUpdate(exercise.Name, exercise.Description, newType)} />
                <EditableRow
                    field="Youtube ID"
                    value={(exercise.Media && exercise.Media.length > 0) ? exercise.Media[0].Data : ""}
                    disabled={disabled}
                    onSave={newID => onMediaUpdate(newID)} />
                <span>{message}</span>
            </div>
        </Paper>
    )

}

// Redux connect
const mapStateToProps = state => {
    const apiToken = getApiTokenSelector(state)
    return {apiToken}
}
export default connect(mapStateToProps, {fetchExercise, updateExercise, createMedia, updateMedia})(Exercise)
