import AppConfiguration from "../config/Config.json"
import ApiCall from "../api/ApiCall.js"
import UsersApi from "../api/Users.js"

const usersURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Users
const usersApi = new UsersApi(usersURL)

// Actions

export const loadUserFacilities = (apiToken, username, onError, onSuccess) => {
    return dispatch => {
        dispatch(loadUserFacilitiesAction())
        ApiCall(dispatch, usersApi.listAccessibleFacilities)(apiToken, username).then(response => {

            const userFacilities = []
            for(const facility of response) {
                userFacilities.push({
                    id: facility.ID,
                    name: facility.Name,
                    description: facility.Description,
                })
            }
            userFacilities.sort((a,b) => {
                if(a.name.toLowerCase() < b.name.toLowerCase()) return -1
                return 1
            })
            dispatch(setUserFacilitiesAction(userFacilities))
            if(onSuccess) onSuccess()
        }).catch(error =>{
            dispatch(setErrorAction())
            onError(error)
        })
    }
}

// Constants

export const STATUS_UNINITIALIZED = "UNINITIALIZED"
export const STATUS_LOADING = "LOADING"
export const STATUS_ERROR = "ERROR"
export const STATUS_LOADED = "LOADED"

// Action Creators

export const LOAD_USER_FACILITIES = "LOAD_USER_FACILITIES"
export const SET_ERROR = "SET_LOAD_USER_FACILITIES_ERROR"
export const SET_USER_FACILITIES = "SET_USER_FACILITIES"

const loadUserFacilitiesAction = () => {
    return {type: LOAD_USER_FACILITIES}
}

const setErrorAction = error => {
    return {type: SET_ERROR, payload: error}
}

const setUserFacilitiesAction = list => {
    return {type: SET_USER_FACILITIES, payload: list}
}

// Selector(s)

export const getUserFacilitiesSelector = store => store.userFacilities
export const getUserFacilitiesLoadStatus = store => getUserFacilitiesSelector(store).loadStatus
export const getUserFacilitiesListSelector = store => getUserFacilitiesSelector(store).list
export const getUserFacilitiesMapSelector = store => getUserFacilitiesSelector(store).map

export const areUserFacilitiesMissingSelector = store => getUserFacilitiesLoadStatus(store) === STATUS_UNINITIALIZED
