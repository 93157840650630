import React from 'react';
import { getLoggedInUserSelector, changePassword } from "../../state/Auth"
import { makeStyles, TextField, Button } from '@material-ui/core';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}))

const ChangePasswordForm = (props) => {

    // inputs
    const {user, changePassword} = props

    // state
    const classes = useStyles();
    const [password, setPassword] = React.useState("")
    const [confirm, setConfirm] = React.useState("")
    const [error, setError] = React.useState("")
    const [changing, setChanging] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const disableSubmit = (
        password.length === 0
        || confirm.length === 0
        || password.length !== confirm.length
        || changing
    )

    // event handlers
    const onInputChange = setter => e => {
        if(error.length !== 0) {
            setError("")
        }
        if(message.length !== 0) {
            setMessage("")
        }
        setter(e.target.value)
    }

    const onSubmit = () => {
        if(password.length < 8) {
            setError("New password must be at least 8 characters")
        } else if(password !== confirm) {
            setError("Password and Confirmation don't match")
        } else  {
            changePassword(
                user,
                password,
                error => {
                    if(error?.message === "Unauthorized") {
                        // do nothing. user will get logged out
                        return
                    }
                    setChanging(false)
                    setError(error.message)
                },
                () => {
                    setChanging(false)
                    setPassword("")
                    setConfirm("")
                    setMessage("Password Change Successful!")
                },
            )
        }
    }

    // JSX
    return (
        <form
            onSubmit={e=>{
                e.preventDefault();
                setChanging(true)
                onSubmit()
            }}
            noValidate
        >
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                type="password"
                id="password"
                value={password}
                error={error.length === 0 ? false : true}
                onChange={onInputChange(setPassword)}
                autoComplete="new-password"
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirm"
                label="Confirm New Password"
                type="password"
                id="confirm"
                value={confirm}
                error={error.length === 0 ? false : true}
                helperText={error || message}
                onChange={onInputChange(setConfirm)}
                autoComplete="password-confirm"
            />
            <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={disableSubmit}
                className={classes.submit}
                id="change-password"
            >Change Password</Button>
        </form>
    )
}

// Redux connect
const mapStateToProps = state => {
    const user = getLoggedInUserSelector(state)
    return {user}
}
export default connect(mapStateToProps, {changePassword})(ChangePasswordForm)