import {logout} from "../state/Auth"

const parseResponseBody = async response => {

    // first parse body as string
    const body = await response.text()

    // check if response should be json
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return JSON.parse(body)
    }

    // otherwise return text
    return body
}

const parseErrorResponse = async (dispatch, response) => {
    const body = await parseResponseBody(response)
    if(body?.StatusCode) {
        if(body.StatusCode === 401) {
            logout()(dispatch)
        }
        return body.StatusCode === 500 ? "Server Error" : body.Detail
    }
    return body
}

export default (dispatch, apiCall ) => async(...callArgs) => {
    const response = await apiCall(...callArgs)
    if(response.ok) {
        return await parseResponseBody(response)
    }
    const error = await parseErrorResponse(dispatch, response)
    throw Error(error)
}
