import { isUserLoggedInSelector, getLoggedInUserSelector } from "../Auth"
import { areExercisesMissingSelector, loadExercises } from "../Exercises"

const shouldRun = state => {
    if(!isUserLoggedInSelector(state)) return false
    const user = getLoggedInUserSelector(state)
    return (user.isSuperAdmin) && areExercisesMissingSelector(state)
}

const run = (state, dispatch) => {
    const user = getLoggedInUserSelector(state)
    const {apiToken} = user
    const loadExercisesWithDispatch = loadExercises(
        apiToken, error => console.error(error)
    )
    loadExercisesWithDispatch(dispatch)
}

export default {shouldRun, run}