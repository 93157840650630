
import AppConfiguration from "../config/Config.json"
import TokensApi from "../api/Tokens.js"
import UsersApi from "../api/Users.js"
import ApiCall from "../api/ApiCall.js"

const tokensURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Tokens
const tokensApi = new TokensApi(tokensURL)

const usersURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Users
const usersApi = new UsersApi(usersURL)

// Actions

const transformTokenResponseToUserDetails = response => {
    let facilities = response.FacilitiesIDs
    if(! (Symbol.iterator in Object(facilities)) ) {
        facilities = []
    }

    return {
        username : response.Username,
        apiToken : response.Token,
        customerID : response.CustomerID,
        isSuperAdmin : response.IsSuperAdmin,
        facilities : facilities,
    }
}

export const login = (username, password) => {
    return dispatch => {
        dispatch(loginAction())
        ApiCall(dispatch, tokensApi.createOne)(username, password).then(response => {
            dispatch(setUserDetailsAction(transformTokenResponseToUserDetails(response)))
        }).catch(error => {
            dispatch(setErrorAction(error.message))
        })
    }
}

export const clearLoginError = () => {
    return logout()
}

export const logout = () => {
    return dispatch => {
        dispatch(logoutAction())
    }
}

export const changePassword = (user, newPassword, onError, onSuccess) => {
    return dispatch => {
        (async (user, newPassword) => {
            await ApiCall(dispatch, usersApi.changePassword)(user.apiToken, user.username, newPassword)
            return await ApiCall(dispatch, tokensApi.createOne)(user.username, newPassword)
        })(user, newPassword).then(response => {
            dispatch(setUserDetailsAction(transformTokenResponseToUserDetails(response)))
            if(onSuccess) onSuccess()
        }).catch(onError)
    }
}

export const logoutAll = (user) => {
    return dispatch => {
        ApiCall(dispatch, tokensApi.deleteOne)(user.apiToken, user.username).then(response => {
            dispatch(logoutAction())
        }).catch(error => {
            console.error(error)
        })
    }
}

export const onStorageSyncComplete = () => {
    return dispatch => {
        dispatch(storageSyncComplete())
    }
}

// Constants

export const STATUS_LOGGED_OUT = "LOGGED_OUT"
export const STATUS_LOGGING_IN = "LOGGING_IN"
export const STATUS_ERROR = "ERROR"
export const STATUS_LOGGED_IN = "LOGGED_IN"

// Action Creators

export const LOGIN = "LOGIN"
export const SET_ERROR = "SET_LOGIN_ERROR"
export const SET_USER_DETAILS = "SET_USER_DETAILS"
export const LOGOUT = "LOGOUT"
export const STORAGE_SYNC_COMPLETE = "STORAGE_SYNC_COMPLETE"

const loginAction = () => {
    return {type: LOGIN}
}

const setErrorAction = error => {
    return {type: SET_ERROR, payload: error}
}

const setUserDetailsAction = userDetails => {
    return {type: SET_USER_DETAILS, payload: userDetails}
}

const logoutAction = () => {
    return {type: LOGOUT}
}

const storageSyncComplete = () => {
    return {type: STORAGE_SYNC_COMPLETE}
}

// Selector(s)

export const getAuthSelector = store => store.auth
export const getLoginStatusSelector = store => getAuthSelector(store).loginStatus
export const getLoginErrorSelector = store => getLoginStatusSelector(store) === STATUS_ERROR ? getAuthSelector(store).error : ""
export const getLoggedInUserSelector = store => getAuthSelector(store).user

export const isUserLoggingInSelector = store => getLoginStatusSelector(store) === STATUS_LOGGING_IN
export const isUserLoggedInSelector = store => getLoginStatusSelector(store) === STATUS_LOGGED_IN
export const getApiTokenSelector = store => getLoggedInUserSelector(store)?.apiToken
