import React from 'react';
import { makeStyles, TextField, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import AppConfiguration from "../../config/Config.json"
import UsersApi from "../../api/Users.js"
import ApiCall from "../../api/ApiCall.js"

const usersURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Users
const usersApi = new UsersApi(usersURL)

const changePassword = (apiToken, username, newPassword, onError, onSuccess) => {
    return dispatch => {
        ApiCall(dispatch, usersApi.changePassword)
            (apiToken, username, newPassword).then(() => {
                if(onSuccess) onSuccess()
            }).catch(onError)
    }
}

const useStyles = makeStyles(theme => ({
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}))

const ChangePasswordForm = (props) => {

    // inputs
    const {apiToken, username, changePassword} = props

    // state
    const classes = useStyles();
    const [password, setPassword] = React.useState("")
    const [error, setError] = React.useState("")
    const [changing, setChanging] = React.useState(false)
    const [message, setMessage] = React.useState("")
    const disableSubmit = (
        password.length === 0
        || changing
    )

    // event handlers
    const onInputChange = setter => e => {
        if(error.length !== 0) {
            setError("")
        }
        if(message.length !== 0) {
            setMessage("")
        }
        setter(e.target.value)
    }

    const onSubmit = () => {
        if(password.length < 8) {
            setError("New password must be at least 8 characters")
        } else  {
            changePassword(
                apiToken,
                username,
                password,
                error => {
                    if(error?.message === "Unauthorized") {
                        // do nothing. user will get logged out
                        return
                    }
                    setChanging(false)
                    setError(error.message)
                },
                () => {
                    setChanging(false)
                    setPassword("")
                    setMessage("Password Change Successful!")
                },
            )
        }
    }

    // JSX
    return (
        <form
            onSubmit={e=>{
                e.preventDefault();
                setChanging(true)
                onSubmit()
            }}
            noValidate
        >
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="New Password"
                id="password"
                value={password}
                error={error.length === 0 ? false : true}
                helperText={error || message}
                onChange={onInputChange(setPassword)}
                autoComplete="new-password"
            />
            <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={disableSubmit}
                className={classes.submit}
                id="change-password"
            >Change Password</Button>
        </form>
    )
}

export default connect(null, {changePassword})(ChangePasswordForm)