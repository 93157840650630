import React from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
    makeStyles,
    Breadcrumbs,
    Paper,
    Typography,
    Tooltip,
    IconButton,
    TextField,
    TableRow,
    TablePagination,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableFooter,
    Divider,
} from '@material-ui/core';
import { Save, Cancel, Edit, SwapHoriz } from '@material-ui/icons';
import { getApiTokenSelector } from "../../state/Auth"
import { getCustomersMapSelector } from "../../state/Customers"
import {
    getCachedCustomerIDSelector,
    getCachedCustomerFacilitiesMapSelector,
    loadCustomer,
    updateFacility,
} from "../../state/CachedCustomer"
import {
    getCachedFacilityIDSelector,
    getCachedFacilityTagsSelector,
    loadFacility,
} from "../../state/CachedFacility"

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    headingRow: {
        display: "flex",
        flexDirection: "row",
        paddingRight: theme.spacing(2),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    table: {
        overflowY: "auto",
        [theme.breakpoints.down('xs')]: {
            maxHeight: theme.spacing(35),
            minWidth: theme.spacing(35),
        },
        [theme.breakpoints.up('sm')]: {
            maxHeight: theme.spacing(55),
            minWidth: theme.spacing(65),
        },
    },
}))

const Nav = props => {

    const {customer} = props

    return (
        <Breadcrumbs separator=">" aria-label="breadcrumbs">
            <Link color="inherit" to="/customers">Customers</Link>
            {customer && <Link color="inherit" to={`/customers/${customer.id}`}>{customer.name}</Link>}
        </Breadcrumbs>
    )
}

const EditableSummary = props => {

    const {apiToken, customerID, facility, updateFacility} = props

    const classes = useStyles();
    const [editing, setEditing] = React.useState(false)
    const [saving, setSaving] = React.useState(false)
    const [newName, setNewName] = React.useState("")
    const [newDescription, setNewDescription] = React.useState("")

    React.useEffect(() => {
        setNewName(facility.name)
        setNewDescription(facility.description)
    }, [facility, setNewName, setNewDescription])

    const update = () => {

        if(newName === facility.name && newDescription === facility.description) {
            setEditing(false)
            return
        }

        if(!newName) {
            setNewName(facility.name)
            setNewDescription(facility.description)
            setEditing(false)
            return
        }

        setSaving(true)
        updateFacility(
            apiToken,
            customerID,
            {...facility, name: newName, description: newDescription},
            error => {
                console.error(error)
                setSaving(false)
            },
        )
    }

    return editing ? (
        <React.Fragment>
            <div className={classes.section}>
                <div className={classes.headingRow}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="name"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    value={newName}
                    disabled={saving}
                    onChange={e => setNewName(e.target.value)}
                    autoFocus
                    />
                <Tooltip title="Update">
                    <IconButton disabled={saving} onClick={update}>
                        <Save />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                    <IconButton disabled={saving} onClick={() => {
                        setNewName(facility.name)
                        setNewDescription(facility.description)
                        setEditing(false)
                    }}>
                        <Cancel />
                    </IconButton>
                </Tooltip>
                </div>
            </div>
            <div className={classes.section}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="description"
                    label="Description"
                    name="description"
                    autoComplete="description"
                    value={newDescription}
                    disabled={saving}
                    onChange={e => setNewDescription(e.target.value)}
                    autoFocus
                    />
            </div>
        </React.Fragment>
    ) : (
        <React.Fragment>
            <div className={classes.section}>
                <div className={classes.headingRow}>
                <Typography variant="h4">{facility.name}</Typography>
                <Tooltip title="Edit">
                    <IconButton onClick={() => {setEditing(true)}}>
                        <Edit />
                    </IconButton>
                </Tooltip>
                </div>
            </div>
            <div className={classes.section}>
                <Typography variant="h6">{facility.description}</Typography>
            </div>
        </React.Fragment>
    )
}

const Tags = props => {

    const {tags, customerID, facilityID} = props

    const classes = useStyles();
    const [offset, setOffset] = React.useState(0)

    const limit = 10
    const count = tags.length
    const pageEnd = offset+limit < count ? offset+limit : count
    const data = tags.slice(offset, pageEnd)

    const paginationRow = (
        <TableRow>
            <TablePagination
             rowsPerPage={limit}
             rowsPerPageOptions={[]}
             count={count}
             page={offset/limit}
             onChangePage={(e,page)=>{setOffset(page*limit)}}
            />
        </TableRow>
    )

    return (
        <div className={classes.table}>
            {count === 0 && <Typography>No Tags</Typography>}
            {count > 0 &&
            <Table>
                {count > limit && <TableHead>{paginationRow}</TableHead>}
                <TableBody>
                    {data.map(tag => (
                        <TableRow key={tag.id}>
                            <TableCell><Link to={`/customers/${customerID}/facilities/${facilityID}/tags/${tag.id}`}>
                                {tag.name}
                            </Link></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                {count > limit && <TableFooter>{paginationRow}</TableFooter>}
            </Table>
            }
        </div>
    )

}

const Facility = props => {

    const {
        apiToken,
        customers, cachedCustomerID, loadCustomer,
        facilities, cachedFacilityID, loadFacility, updateFacility,
        tags,
    } = props
    const {customerID, facilityID} = useParams()

    const classes = useStyles();
    const [showUsers, setShowUsers] = React.useState(false)

    const customer = customers[customerID]
    const facility = facilities[facilityID]
    const renderFacility = customerID === cachedCustomerID
    const renderTags = facilityID === cachedFacilityID

    React.useEffect(() => {
        if(!renderFacility) {
            loadCustomer(apiToken, customerID, error => console.error(error))
        } else if (!renderTags) {
            loadFacility(apiToken, facilityID, error => console.error(error))
        }
    }, [apiToken, renderFacility, renderTags, customerID, loadCustomer, facilityID, loadFacility])

    if(facilityID !== facility?.id) {
        return (
            <Paper elevation={6} className={classes.main}>
                <Nav customer={customer} />
                <div className={classes.section}>
                    <Typography variant="h4">Facility</Typography>
                </div>
                <div className={classes.section}>
                    <Typography variant="h6">Loading...</Typography>
                </div>
            </Paper>
        )
    }

    const tableTitle = showUsers ? "Users" : "Tags"
    const newLink = showUsers ?
        `/customers/${customerID}/facilities/${facilityID}/users/new` :
        `/customers/${customerID}/facilities/${facilityID}/tags/new`

    const table = showUsers ? (
    <div className={classes.table}>
        {facility.users.length === 0 && <Typography>No Users</Typography>}
        {facility.users.length > 0 &&
        <Table>
            <TableBody>
                {facility.users.map(username => (
                    <TableRow key={username}>
                        <TableCell><Link to={`/customers/${customerID}/facilities/${facilityID}/users/${username}`}>
                            {username}
                        </Link></TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
        }
    </div>
    ) : (
    <Tags tags={tags} customerID={customerID} facilityID={facilityID}  />
    )

    return (
        <Paper elevation={6} className={classes.main}>
            <Nav customer={customer} />
            <EditableSummary
                apiToken={apiToken}
                customerID={customerID}
                facility={facility}
                updateFacility={updateFacility}
                />
            <Divider />
            <div className={classes.section}>
                <div className={classes.headingRow}>
                    <Typography variant="h6">{tableTitle}</Typography>
                    <Tooltip title="Toggle Tags/Users">
                        <IconButton size="small" onClick={() => {setShowUsers(!showUsers)}}>
                            <SwapHoriz />
                        </IconButton>
                    </Tooltip>
                </div>
                <Link color="inherit" to={newLink}>New</Link>
            </div>
            <Divider />
            <div className={classes.section}>
                {(showUsers || renderTags) && table}
            </div>
        </Paper>
    )
}

const mapStateToProps = state => {
    const apiToken = getApiTokenSelector(state)
    const customers = getCustomersMapSelector(state)
    const cachedCustomerID = getCachedCustomerIDSelector(state)
    const facilities = getCachedCustomerFacilitiesMapSelector(state)
    const cachedFacilityID = getCachedFacilityIDSelector(state)
    const tags = getCachedFacilityTagsSelector(state)
    return {apiToken, customers, cachedCustomerID, facilities, cachedFacilityID, tags}
}

export default connect(mapStateToProps, {loadCustomer, loadFacility, updateFacility})(Facility)