import React from 'react';
import { connect } from "react-redux"
import { Switch, Route, Redirect } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { clearLoginError, login, isUserLoggingInSelector, getLoginErrorSelector } from "../state/Auth"

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}
      {' All Rights Reserved '}
      <a href="https://gainznow.com">Chimney Hill Ventures LLC</a>
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/splash.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginView = props => {

  const classes = useStyles();

  const {clearLoginError, login, isUserLoggingIn, loginError} = props
  const [username, setUsername] = React.useState("")
  const [password, setPassword] = React.useState("")
  const disableSubmit = isUserLoggingIn || username.length === 0 || password.length === 0

  const onInputChange = setter => e => {
    if(loginError.length !== 0) {
      clearLoginError()
    }
    setter(e.target.value)
  }

  return (

    <Switch>

      <Route name="login" path="/login" render={ () => {
        return(
          <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                <form
                  className={classes.form}
                  onSubmit={e=>{ e.preventDefault(); login(username,password) }}
                  noValidate
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    onChange={onInputChange(setUsername)}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    error={loginError.length === 0 ? false : true}
                    helperText={loginError}
                    onChange={onInputChange(setPassword)}
                    autoComplete="current-password"
                  />
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={disableSubmit}
                    className={classes.submit}
                    id="signin"
                  >
                    Sign In
                  </Button>
                </form>
              </div>
              <Box mt={8}>
                <Copyright />
              </Box>
            </Grid>
          </Grid>
        )} }
      />

      <Route name="default" path="/"><Redirect exact from="/" to="/login" /></Route>

    </Switch>
  );
}

const mapStateToProps = state => {
  const isUserLoggingIn = isUserLoggingInSelector(state)
  const loginError = getLoginErrorSelector(state)
  return {isUserLoggingIn, loginError}
}

export default connect(mapStateToProps, {clearLoginError, login})(LoginView)