import AppConfiguration from "../config/Config.json"
import ApiCall from "../api/ApiCall.js"
import ExercisesApi from "../api/Exercises.js"

const exercisesURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Exercises
const exercisesApi = new ExercisesApi(exercisesURL)

// Actions

export const loadExercises = (apiToken, onError, onSuccess) => {
    return dispatch => {
        dispatch(loadExercisesAction())
        ApiCall(dispatch, exercisesApi.listAll)(apiToken).then(response => {
            const exercises = []
            for(const exercise of response) {
                exercises.push({
                    id: exercise.ID,
                    name: exercise.Name,
                    description: exercise.Description,
                    type: exercise.Type,
                })
            }
            exercises.sort((a,b) => (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1)
            dispatch(setExercisesAction(exercises))
            if(onSuccess) onSuccess()
        }).catch(error => {
            dispatch(setErrorAction(error))
            onError(error)
        })
    }
}

export const createExercise = (apiToken, exercise, onError, onSuccess) => {
    return dispatch => {
        ApiCall(dispatch, exercisesApi.createOne)(apiToken, {
            Name: exercise.name,
            Description: exercise.description,
            Type: exercise.type,
        }).then(response => {
            loadExercises(apiToken, error => console.error(error))(dispatch)
            if(onSuccess) onSuccess(response)
        }).catch(onError)
    }
}

export const updateExercise = (apiToken, exercise, onError, onSuccess) => {
    return dispatch => {
        ApiCall(dispatch, exercisesApi.updateOne)(apiToken, {
            ID: exercise.id,
            Name: exercise.name,
            Description: exercise.description,
            Type: exercise.type,
        }).then(response => {
            loadExercises(apiToken, error => console.error(error))(dispatch)
            if(onSuccess) onSuccess(response)
        }).catch(onError)
    }
}

// Constants

export const STATUS_UNINITIALIZED = "UNINITIALIZED"
export const STATUS_LOADING = "LOADING"
export const STATUS_ERROR = "ERROR"
export const STATUS_LOADED = "LOADED"

// Action Creators

export const LOAD_EXERCISES = "LOAD_EXERCISES"
export const SET_ERROR = "SET_LOAD_EXERCISES_ERROR"
export const SET_EXERCISES = "SET_EXERCISES"

const loadExercisesAction = () => {
    return {type: LOAD_EXERCISES}
}

const setErrorAction = error => {
    return {type: SET_ERROR, payload: error}
}

const setExercisesAction = exercises => {
    return {type: SET_EXERCISES, payload: exercises}
}

// Selector(s)

export const getExercisesSelector = store => store.exercises
export const getExercisesLoadStatus = store => getExercisesSelector(store).loadStatus
export const getExercisesListSelector = store => getExercisesSelector(store).list
export const getExercisesMapSelector = store => getExercisesSelector(store).map
export const getExercisesErrorSelector = store => getExercisesSelector(store).error

export const areExercisesMissingSelector = store => getExercisesLoadStatus(store) === STATUS_UNINITIALIZED
export const isErrorLoadingExercisesSelector = store => getExercisesLoadStatus(store) === STATUS_ERROR
