import {
    STATUS_LOGGED_OUT,
    STATUS_LOGGING_IN,
    STATUS_ERROR,
    STATUS_LOGGED_IN,
    LOGIN,
    SET_ERROR,
    SET_USER_DETAILS,
    LOGOUT,
    STORAGE_SYNC_COMPLETE,
} from "../Auth"

const initialState = { loginStatus : STATUS_LOGGED_OUT }

export default function(state=initialState, action) {
    switch(action.type) {
        case LOGIN: {
            return { loginStatus : STATUS_LOGGING_IN }
        }
        case SET_ERROR : {
            return { loginStatus : STATUS_ERROR, error : action.payload }
        }
        case SET_USER_DETAILS: {
            return { syncToStorage : true, loginStatus : STATUS_LOGGED_IN, user : action.payload }
        }
        case LOGOUT : {
            return { ...initialState, syncToStorage : true }
        }
        case STORAGE_SYNC_COMPLETE : {
            return { ...state, syncToStorage : false }
        }
        default:
            return state
    }
}
