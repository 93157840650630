import React from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { 
    Paper,
    makeStyles,
    Breadcrumbs,
    Typography,
    Divider,
    Button,
    IconButton,
    TextField,
    Tooltip,
    TableRow,
    TablePagination,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableFooter,
} from '@material-ui/core';
import { getApiTokenSelector } from "../../state/Auth"
import { getCustomersMapSelector, updateCustomer } from "../../state/Customers"
import {
    getCachedCustomerIDSelector,
    getCachedCustomerFacilitiesListSelector,
    loadCustomer,
} from "../../state/CachedCustomer"
import { Edit, Cancel, Save } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    headingRow: {
        display: "flex",
        flexDirection: "row",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    table: {
        overflowY: "auto",
        [theme.breakpoints.down('xs')]: {
            maxHeight: theme.spacing(25),
            minWidth: theme.spacing(35),
        },
        [theme.breakpoints.up('sm')]: {
            maxHeight: theme.spacing(55),
            minWidth: theme.spacing(65),
        },
    },
}))

const Nav = () => {
    return (
        <Breadcrumbs separator=">" aria-label="breadcrumbs">
            <Link color="inherit" to="/customers">Customers</Link>
        </Breadcrumbs>
    )
}

const Heading = props => {

    const {apiToken, customer, updateCustomer} = props

    const classes = useStyles();
    const [editing, setEditing] = React.useState(false)
    const [saving, setSaving] = React.useState(false)
    const [newName, setNewName] = React.useState("")

    React.useEffect(() => {
       setNewName(customer.name)
    }, [customer, setNewName])

    const update = () => {

        if(newName === customer.name) {
            setEditing(false)
            return
        }

        if(!newName) {
            setNewName(customer.name)
            setEditing(false)
            return
        }

        setSaving(true)
        updateCustomer(
            apiToken,
            {...customer, name: newName},
            error => {
                console.error(error)
                setSaving(false)
            },
            () => setSaving(false),
        )
    }

    return (
        <div className={classes.headingRow}>

            {!editing &&
            <React.Fragment>
                <Typography variant="h4">{customer.name}</Typography>
                <Tooltip title="Edit">
                    <IconButton onClick={() => {setEditing(true)}}>
                        <Edit />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
            }
            
            {editing &&
            <React.Fragment>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="name"
                    label="Name"
                    name="name"
                    autoComplete="name"
                    value={newName}
                    disabled={saving}
                    onChange={e => setNewName(e.target.value)}
                    autoFocus
                    />
                <Tooltip title="Update">
                    <IconButton disabled={saving} onClick={update}>
                        <Save />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Cancel">
                    <IconButton disabled={saving} onClick={() => {
                        setNewName(customer.name)
                        setEditing(false)
                    }}>
                        <Cancel />
                    </IconButton>
                </Tooltip>
            </React.Fragment>
            }
        </div>
    )
}

const Facilities = props => {

    const {facilities, customerID} = props

    const classes = useStyles();
    const [offset, setOffset] = React.useState(0)

    const limit = 10
    const count = facilities.length
    const pageEnd = offset+limit < count ? offset+limit : count
    const data = facilities.slice(offset, pageEnd)

    const paginationRow = (
        <TableRow>
            <TablePagination
             rowsPerPage={limit}
             rowsPerPageOptions={[]}
             count={count}
             page={offset/limit}
             onChangePage={(e,page)=>{setOffset(page*limit)}}
            />
        </TableRow>
    )

    return (
        <div className={classes.table}>
            {count === 0 && <Typography>No Facilities</Typography>}
            {count > 0 &&
            <Table>
                {count > limit && <TableHead>{paginationRow}</TableHead>}
                <TableBody>
                    {data.map(facility => (
                        <TableRow key={facility.id}>
                            <TableCell><Link to={`/customers/${customerID}/facilities/${facility.id}`}>
                                {facility.name}
                            </Link></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                {count > limit && <TableFooter>{paginationRow}</TableFooter>}
            </Table>
            }
        </div>
    )
}

const Customer = props => {

    const {
        apiToken, customers, cachedCustomerID, facilities,
        updateCustomer, loadCustomer,
    } = props
    const {customerID} = useParams()

    const classes = useStyles();
    const customer = customers[customerID]
    const renderFacilities = customerID === cachedCustomerID

    React.useEffect(() => {
        if(!renderFacilities) {
            loadCustomer(apiToken, customerID, error => console.error(error))
        }
    }, [apiToken, renderFacilities, customerID, loadCustomer])

    if(customerID !== customer?.id) {
        return (
            <Paper elevation={6} className={classes.main}>
                <Nav />
                <div className={classes.section}>
                    <Typography variant="h4">Customer</Typography>
                </div>
                <div className={classes.section}>
                    <Typography variant="h6">Loading...</Typography>
                </div>
            </Paper>
        )
    }

    return (
    <Paper elevation={6} className={classes.main}>
        <Nav />
        <div className={classes.section}>
            <Heading
                apiToken={apiToken}
                customer={customer}
                updateCustomer={updateCustomer}
            />
        </div>
        <Divider />
        <div className={classes.section}>
            <Button
                fullWidth
                type="button"
                variant="contained"
                color="primary"
                className={classes.submit}
                id="toggle-active"
                onClick={() =>{
                    updateCustomer(
                        apiToken,
                        {...customer, active: !customer.active},
                        error => console.error(error)
                    )
                }}
            >{customer.active ? "Deactivate" : "Activate"}</Button>
        </div>
        <Divider />
        <div className={classes.section}>
            <Typography variant="h6">Facilities</Typography>
            <Link color="inherit" to={`/customers/${customerID}/facilities/new`}>
                New
            </Link>
        </div>
        <Divider />
        <div className={classes.section}>
            {renderFacilities &&
            <Facilities facilities={facilities} customerID={customerID} />
            }
        </div>
    </Paper>
    )
}

const mapStateToProps = state => {
    const apiToken = getApiTokenSelector(state)
    const customers = getCustomersMapSelector(state)
    const cachedCustomerID = getCachedCustomerIDSelector(state)
    const facilities = getCachedCustomerFacilitiesListSelector(state)
    return {apiToken, customers, cachedCustomerID, facilities}
}

export default connect(mapStateToProps, {updateCustomer, loadCustomer})(Customer)
