export default class TagsApi {

    constructor(apiUrl) {
         this.apiUrl = apiUrl;
    }

    list = async (apiToken, facilityID) => {

        if(!apiToken || !facilityID) {
            throw Error("apiToken and facilityID required to load tags")
        }

        return await fetch(
            `${this.apiUrl}?facilityID=${facilityID}`,
            {
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )
    }

    getOne = async (apiToken, id) => {

        if(!apiToken || !id) {
            throw Error("apiToken and id required to fetch tag")
        }

        return await fetch(
            `${this.apiUrl}/${id}?scanned=false`,
            {
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )
    }

    deleteOne = async (apiToken, id) => {

        if(!apiToken || !id) {
            throw Error("apiToken and id required to delete tag")
        }

        return await fetch(
            `${this.apiUrl}/${id}`,
            {
                method: 'delete',
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )
    }

    createOne = async (apiToken, facilityID, exerciseID, name) => {

        if(!apiToken || !facilityID || !exerciseID || !name) {
            throw Error("apiToken and tag details required to create tag")
        }

        return await fetch(
            `${this.apiUrl}?facilityID=${facilityID}`,
            {
                method: 'post',
                body: JSON.stringify({Name: name, ExerciseID: exerciseID}),
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )
    }
}
