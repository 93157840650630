export default class ExercisesApi {

    constructor(apiUrl) {
         this.apiUrl = apiUrl;
    }

    getOne = async (apiToken, exerciseID) => {

        if(!apiToken || !exerciseID) {
            throw Error("apiToken AND exerciseID required to load exercise")
        }

        return await fetch(
            `${this.apiUrl}/${exerciseID}`,
            {
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )
    }

    listAll = async (apiToken) => {

        if(!apiToken) {
            throw Error("apiToken required to list exercises")
        }

        return await fetch(
            `${this.apiUrl}`,
            {
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )

    }

    createOne = async (apiToken, newExercise) => {

        if(!apiToken || !newExercise?.Name || !newExercise?.Description || !newExercise.Type ) {
            throw Error("apiToken and exercise Name, Description, and Type required to create exercise")
        }

        return await fetch(
            `${this.apiUrl}`,
            {
                method: 'post',
                body: JSON.stringify(newExercise),
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )
    }

    updateOne = async (apiToken, exercise) => {

        if(!apiToken || !exercise?.ID || !exercise.Name || !exercise?.Description || !exercise.Type ) {
            throw Error("apiToken and exercise ID, Name, Description, and Type required to update exercise")
        }

        return await fetch(
            `${this.apiUrl}/${exercise.ID}`,
            {
                method: 'put',
                body: JSON.stringify({
                    Name: exercise.Name,
                    Description: exercise.Description,
                    Type: exercise.Type,
                }),
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )
    }

}