import AppConfiguration from "../config/Config.json"
import ApiCall from "../api/ApiCall.js"
import TagsApi from "../api/Tags.js"

const tagsURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Tags
const tagsApi = new TagsApi(tagsURL)

// Actions
export const loadFacility = (apiToken, facilityID) => async dispatch => {
    dispatch(loadTagsForFacility(facilityID))
    try {
        const response = await ApiCall(dispatch, tagsApi.list)(apiToken, facilityID)
        dispatch(setTagsForFacility(transformResponse(response)))
    } catch(error) {
        dispatch(setErrorAction(error))
        throw error
    }
}

function transformResponse(response) {
    const tags = []
    for(const tag of response) {
        const exercises = []
        for(const exercise of tag.Exercises) {
            exercises.push({
                id: exercise.ID,
                name: exercise.Name,
                description: exercise.Description,
                primaryMedia: exercise.PrimaryMedia ? {
                    id: exercise.PrimaryMedia.ID,
                    type: exercise.PrimaryMedia.Type,
                    data: exercise.PrimaryMedia.Data,
                }: null,
            })
        }
        tags.push({
            id: tag.ShortID,
            name: tag.Name,
            exercises: exercises,
        })
    }
    tags.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase()) return -1
        return 1
    })
    return tags
}

// Constants

export const STATUS_UNINITIALIZED = "UNINITIALIZED"
export const STATUS_LOADING = "LOADING"
export const STATUS_ERROR = "ERROR"
export const STATUS_LOADED = "LOADED"

// Action Creators

export const LOAD_TAGS_FOR_FACILITY = "LOAD_TAGS_FOR_FACILITY"
export const SET_ERROR = "SET_LOAD_TAGS_FOR_FACILITY_ERROR"
export const SET_TAGS_FOR_FACILITY = "SET_TAGS_FOR_FACILITY"

const loadTagsForFacility = facilityID => {
    return {type: LOAD_TAGS_FOR_FACILITY, payload: facilityID}
}

const setErrorAction = error => {
    return {type: SET_ERROR, payload: error}
}

const setTagsForFacility = tags => {
    return {type: SET_TAGS_FOR_FACILITY, payload: tags}
}

// Selector(s)

export const getCachedFacilitySelector = store => store.cachedFacility
export const getCachedFacilityIDSelector = store => getCachedFacilitySelector(store).id
export const getCachedFacilityLoadStatusSelector = store => getCachedFacilitySelector(store).loadStatus
export const getCachedFacilityTagsSelector = store => getCachedFacilitySelector(store).tags
