import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    Paper,
    Typography,
    Divider,
    makeStyles,
} from '@material-ui/core';
import { getLoggedInUserSelector } from "../../state/Auth"

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('xs')]: {
            maxHeight: theme.spacing(40),
            minWidth: theme.spacing(35),
        },
        [theme.breakpoints.up('sm')]: {
            maxHeight: theme.spacing(65),
            minWidth: theme.spacing(65),
        },
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
}))

const Home = props => {

    const {user} = props

    const classes = useStyles();

    return (
        <Paper elevation={6} className={classes.main}>
            <div className={classes.section}>
                <Typography variant="h4">{user.username}</Typography>
            </div>
            <Divider />
            <div className={classes.section}>
                <Link color="inherit" to="/customers">Customers</Link>
            </div>
            <Divider />
            <div className={classes.section}>
                <Link color="inherit" to="/exercises">Exercises</Link>
            </div>
        </Paper>
    )
}

const mapStateToProps = state => {
    const user = getLoggedInUserSelector(state)
    return {user}
}

export default connect(mapStateToProps)(Home)
