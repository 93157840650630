export default class CustomersApi {

    constructor(apiUrl) {
         this.apiUrl = apiUrl;
    }

    getOne = async (apiToken, customerID) => {

        if(!apiToken || !customerID) {
            throw Error("apiToken AND customerID required to load customer")
        }

        return await fetch(
            `${this.apiUrl}/${customerID}`,
            {
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )
    }

    listAll = async (apiToken) => {

        if(!apiToken) {
            throw Error("apiToken required to list customers")
        }

        return await fetch(
            `${this.apiUrl}`,
            {
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )

    }

    createOne = async (apiToken, newCustomer) => {

        if(!apiToken || !newCustomer?.Name ) {
            throw Error("apiToken and customer Name required to create customer")
        }

        return await fetch(
            `${this.apiUrl}`,
            {
                method: 'post',
                body: JSON.stringify(newCustomer),
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )
    }

    updateOne = async (apiToken, customer) => {

        if(!apiToken || !customer?.ID || !customer.Name ) {
            throw Error("apiToken and customer ID and Name required to update customer")
        }

        return await fetch(
            `${this.apiUrl}/${customer.ID}`,
            {
                method: 'put',
                body: JSON.stringify({
                    Name: customer.Name,
                    Active: customer.Active,
                }),
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )
    }

}