import AppConfiguration from "../config/Config.json"
import ApiCall from "../api/ApiCall.js"
import CustomersApi from "../api/Customers.js"

const customersURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Customers
const customersApi = new CustomersApi(customersURL)

// Actions

export const loadUserCustomer = (apiToken, customerID, onError, onSuccess) => {
    return dispatch => {
        dispatch(loadUserCustomerAction())
        ApiCall(dispatch, customersApi.getOne)(apiToken, customerID).then(response => {
            dispatch(setUserCustomerAction({id: response.ID, name: response.Name}))
            if(onSuccess) onSuccess()
        }).catch(error =>{
            dispatch(setErrorAction())
            onError(error)
        })
    }
}

// Constants

export const STATUS_UNINITIALIZED = "UNINITIALIZED"
export const STATUS_LOADING = "LOADING"
export const STATUS_ERROR = "ERROR"
export const STATUS_LOADED = "LOADED"

// Action Creators

export const LOAD_USER_CUSTOMER = "LOAD_USER_CUSTOMER"
export const SET_ERROR = "SET_LOAD_USER_CUSTOMER_ERROR"
export const SET_USER_CUSTOMER = "SET_USER_CUSTOMER"

const loadUserCustomerAction = () => {
    return {type: LOAD_USER_CUSTOMER}
}

const setErrorAction = error => {
    return {type: SET_ERROR, payload: error}
}

const setUserCustomerAction = customer => {
    return {type: SET_USER_CUSTOMER, payload: customer}
}

// Selector(s)

export const getUserCustomerSelector = store => store.userCustomer
export const getUserCustomerLoadStatus = store => getUserCustomerSelector(store).loadStatus
export const getUserCustomerDetailsSelector = store => getUserCustomerSelector(store).details

export const isUserCustomerMissingSelector = store => getUserCustomerLoadStatus(store) === STATUS_UNINITIALIZED
