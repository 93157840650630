import loadExercises from './loadExercises'
import loadCustomers from "./loadCustomers"
import loadUserFacilities from "./loadUserFacilities"
import loadUserCustomer from "./loadUserCustomer"
import syncAuthToLocalStorage from './syncAuthToLocalStorage'

export default [

    // on user login
    loadExercises,
    loadCustomers,
    loadUserFacilities,
    loadUserCustomer,

    // local storage sync
    syncAuthToLocalStorage,
]
