export default class UsernamesApi {

    constructor(apiUrl) {
         this.apiUrl = apiUrl;
    }

    isAvailable = async (username) => {

        if(!username) {
            throw Error("username required to check availability")
        }

        return await fetch(`${this.apiUrl}/${username}`)
    }
}