import React from 'react';
import { connect } from 'react-redux';
import {
    Paper,
    Typography,
    Divider,
    makeStyles,
    TableHead,
    TableCell,
    TableRow,
    Table,
    TableBody,
    TableFooter,
    TablePagination,
} from '@material-ui/core';
import { getCustomersListSelector } from "../../state/Customers"
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    label: {
        fontWeight: "700",
    },
    table: {
        overflowY: "auto",
        [theme.breakpoints.down('xs')]: {
            maxHeight: theme.spacing(45),
            minWidth: theme.spacing(35),
        },
        [theme.breakpoints.up('sm')]: {
            maxHeight: theme.spacing(70),
            minWidth: theme.spacing(65),
        },
    },
}))

const Customers = props => {

    const {customers} = props

    const classes = useStyles();
    const [offset, setOffset] = React.useState(0)

    const limit = 10
    const count = customers.length
    const pageEnd = offset+limit < count ? offset+limit : count
    const data = customers.slice(offset, pageEnd)

    const paginationRow = (
        <TableRow>
            <TablePagination
             rowsPerPage={limit}
             rowsPerPageOptions={[]}
             count={count}
             page={offset/limit}
             onChangePage={(e,page)=>{setOffset(page*limit)}}
            />
        </TableRow>
    )

    return (
        <Paper elevation={6} className={classes.main}>
            <div className={classes.section}>
                <Typography variant="h4">Customers</Typography>
            </div>
            <Divider />
            <div className={classes.section}>
                <Link color="inherit" to={`/customers/new`}>New</Link>
            </div>
            <Divider />
            <div className={classes.section}>
                <div className={classes.table}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.label}>Name</TableCell>
                            <TableCell className={classes.label}>Active</TableCell>
                        </TableRow>
                        {count > limit && paginationRow}
                    </TableHead>
                    <TableBody>
                        {data.map(customer => (
                            <TableRow key={customer.id}>
                                <TableCell><Link to={`/customers/${customer.id}`}>{customer.name}</Link></TableCell>
                                <TableCell>{`${customer.active}`}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    {count > limit && <TableFooter>{paginationRow}</TableFooter>}
                </Table>
                </div>
            </div>
        </Paper>
    )
}

const mapStateToProps = state => {
    const customers = getCustomersListSelector(state)
    return {customers}
}

export default connect(mapStateToProps)(Customers)
