import React from 'react';
import { connect } from "react-redux"
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { CssBaseline, AppBar, Toolbar, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { ExitToApp, Home, Person } from "@material-ui/icons"
import { logout, getLoggedInUserSelector } from "../state/Auth"
import UserSettings from './user/UserSettings';
import AdminFacility from './admin/Facility';
import AdminHome from './admin/Home';
import AdminTag from './admin/Tag';
import SuperAdminHome from './super-admin/Home';
import NewCustomer from './super-admin/NewCustomer';
import Customer from './super-admin/Customer';
import SuperAdminFacility from './super-admin/Facility';
import NewFacility from './super-admin/NewFacility';
import NewUser from './super-admin/NewUser';
import User from './super-admin/User';
import SuperAdminTag from './super-admin/Tag';
import NewExercise from './super-admin/NewExercise';
import Customers from './super-admin/Customers';
import Exercises from './super-admin/Exercises';
import Exercise from './super-admin/Exercise';
import CreateTags from './super-admin/CreateTags';

const AdminRoutes = () => {
    return (
        <Switch>
            <Route path="/me"><UserSettings /></Route>
            <Route path="/facilities/:facilityID/tags/:tagID"><AdminTag /></Route>
            <Route path="/facilities/:facilityID"><AdminFacility /></Route>
            <Route exact path="/"><AdminHome /></Route>
            <Route ><Redirect exact to="/" /></Route>
        </Switch>
    )
}

const SuperAdminRoutes = () => {
    return (
        <Switch>

            <Route path="/me"><UserSettings /></Route>

            <Route exact path="/exercises"><Exercises /></Route>
            <Route exact path="/exercises/new"><NewExercise /></Route>
            <Route exact path="/exercises/:exerciseID"><Exercise /></Route>

            <Route exact path="/customers"><Customers /></Route>
            <Route exact path="/customers/new"><NewCustomer /></Route>
            <Route exact path="/customers/:customerID"><Customer /></Route>
            <Route exact path="/customers/:customerID/facilities/new"><NewFacility /></Route>
            <Route exact path="/customers/:customerID/facilities/:facilityID"><SuperAdminFacility /></Route>
            <Route exact path="/customers/:customerID/facilities/:facilityID/users/new"><NewUser /></Route>
            <Route exact path="/customers/:customerID/facilities/:facilityID/users/:username"><User /></Route>
            <Route exact path="/customers/:customerID/facilities/:facilityID/tags/new"><CreateTags /></Route>
            <Route exact path="/customers/:customerID/facilities/:facilityID/tags/:tagID"><SuperAdminTag /></Route>

            <Route exact path="/"><SuperAdminHome /></Route>
            <Route ><Redirect exact to="/" /></Route>
        </Switch>
    )
}

const useStyles = makeStyles(theme => ({
    main : {
        height : '100vh',
        backgroundImage: 'url(/splash.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        height : "48px",
        width: "128px"
    },
    nav: {
        marginLeft: 'auto',
    },
    content : {
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down('xs')]: {
            padding : theme.spacing(5),
        },
        [theme.breakpoints.up('sm')]: {
            padding : theme.spacing(10),
        },
    },
    link : {
        color: "inherit",
    }
}))

const MainView = props => {
    const classes = useStyles();

    const {user, logout} = props

    const ToolbarIcon = (props) => {
        return (
            <Tooltip title={props.toolTip}>
                <IconButton color="inherit" onClick={props.onClick}>
                    {props.children}
                </IconButton>
            </Tooltip>
        )
    }
    
    return (
        <React.Fragment>
            <CssBaseline />
            <div className={classes.main}>
                <AppBar position="sticky" color="default" elevation={0} className={classes.appbar}>
                    <Toolbar className={classes.toolbar}>
                        <img className={classes.toolbarTitle} src="/logo-red.png" alt="Gainz" />

                        <div className={classes.nav}>
                            <Link to="/" className={classes.link}>
                                <ToolbarIcon toolTip="Home"><Home /></ToolbarIcon>
                            </Link>
                            <Link to="/me" className={classes.link}>
                                <ToolbarIcon toolTip="User Settings"><Person /></ToolbarIcon>
                            </Link>
                            <ToolbarIcon toolTip="Logout" onClick={logout}>
                                <ExitToApp />
                            </ToolbarIcon>
                        </div>
                    </Toolbar>
                </AppBar>
                <div className={classes.content}>
                    {!user.isSuperAdmin && <AdminRoutes />}
                    {user.isSuperAdmin && <SuperAdminRoutes />}
                </div>
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    const user = getLoggedInUserSelector(state)
    return {user}
}

export default connect(mapStateToProps, {logout})(MainView)