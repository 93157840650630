import {combineReducers} from "redux";

// auth
import auth from "./Auth"
import userCustomer from "./UserCustomer"

// admin
import userFacilities from "./UserFacilities"
import cachedFacility from "./CachedFacility"

// super admin
import exercises from "./Exercises"
import customers from "./Customers"
import cachedCustomer from "./CachedCustomer"

export default combineReducers({

    // auth
    auth,
    userCustomer,

    // admin
    userFacilities,
    cachedFacility,

    // super admin
    exercises,
    customers,
    cachedCustomer,
})