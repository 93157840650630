import { isUserLoggedInSelector, getLoggedInUserSelector } from "../Auth"
import { isUserCustomerMissingSelector, loadUserCustomer } from "../UserCustomer"

const shouldRun = state => {
    return isUserLoggedInSelector(state) && isUserCustomerMissingSelector(state)
}

const run = (state, dispatch) => {

    const user = getLoggedInUserSelector(state)
    const {apiToken, customerID} = user
    const loadUserCustomerWithDispatch = loadUserCustomer(
        apiToken, customerID, error => console.error(error),
    )
    loadUserCustomerWithDispatch(dispatch)
}

export default {shouldRun, run}