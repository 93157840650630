import {
    LOAD_USER_CUSTOMER,
    SET_ERROR,
    SET_USER_CUSTOMER,
    STATUS_UNINITIALIZED,
    STATUS_LOADING,
    STATUS_LOADED,
    STATUS_ERROR,
} from "../UserCustomer";
import { LOGOUT } from "../Auth";

const initialState = { loadStatus : STATUS_UNINITIALIZED }

export default function(state=initialState, action) {
    switch(action.type) {
        case LOAD_USER_CUSTOMER: {
            return { loadStatus: STATUS_LOADING }
        }
        case SET_ERROR : {
            return { loadStatus : STATUS_ERROR, error : action.payload }
        }
        case SET_USER_CUSTOMER : {
            return { loadStatus : STATUS_LOADED, details : action.payload }
        }
        case LOGOUT : {
            return initialState
        }
        default:
            return state
    }
}
