import {onStorageSyncComplete as onAuthComplete} from '../Auth'

const shouldRun = state => {
    return state.auth.syncToStorage
}

const run = (state, dispatch) => {
    const toSync = {...state.auth}
    delete toSync.syncToStorage
    try {
        (onAuthComplete())(dispatch)
        localStorage.setItem("auth", JSON.stringify(toSync))
    } catch {
        // ignore
    }
}

export default {shouldRun, run}