export default class FacilitiesApi {

    constructor(apiUrl) {
         this.apiUrl = apiUrl;
    }

    listAll = async (apiToken, customerID) => {

        if(!apiToken || !customerID) {
            throw Error("apiToken and customerID required to list facilities")
        }

        return await fetch(
            `${this.apiUrl}?customerID=${customerID}`,
            {
                headers: new Headers({
                    'Authorization': `Bearer ${apiToken}`
                })
            }
        )
    }

    createOne = async (apiToken, customerID, newFacility) => {

        if(!apiToken || !customerID || !newFacility.Name) {
            throw Error("apiToken, customerID, and facility name required to create facility")
        }

        return await fetch(
            `${this.apiUrl}?customerID=${customerID}`,
            {
                method: 'post',
                body: JSON.stringify(newFacility),
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )
    }

    updateOne = async (apiToken, facility) => {

        if(!apiToken || !facility?.ID || !facility.Name ) {
            throw Error("apiToken and facility ID and Name required to update customer")
        }

        return await fetch(
            `${this.apiUrl}/${facility.ID}`,
            {
                method: 'put',
                body: JSON.stringify({
                    Name: facility.Name,
                    Description: facility.Description,
                }),
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )
    }
}