import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import rootReducer from "./reducers";
import backgroundTasks from "./background-tasks";

const middlewares = [thunk]

const buildStateFromStorage = () => {
    const initialState = {}
    try {
        const auth = JSON.parse(localStorage.getItem("auth"))
        if (auth?.loginStatus) {
            initialState.auth = auth
        }
    } catch(e) {
        console.warn("Error loading state from local storage", e)
    }
    return initialState
}

const backgroundTasksSubscriber = store => {
    return () => {
        const state = store.getState()
        backgroundTasks.forEach(task => {
            if(task.shouldRun(state)) {
                task.run(state, store.dispatch)
            }
        });
    }
}

export default (() => {

    // create store
    const store = createStore(rootReducer, buildStateFromStorage(), applyMiddleware(...middlewares))

    // run background tasks with initial state and subscribe for future changes
    const runBackgroundTasks = backgroundTasksSubscriber(store)
    runBackgroundTasks()
    store.subscribe(runBackgroundTasks)

    return store
}) ()
