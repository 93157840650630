import AppConfiguration from "../config/Config.json"
import ApiCall from "../api/ApiCall.js"
import CustomersApi from "../api/Customers.js"

const customersURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Customers
const customersApi = new CustomersApi(customersURL)

// Actions

export const loadCustomers = (apiToken, onError, onSuccess) => {
    return dispatch => {
        dispatch(loadCustomersAction())
        ApiCall(dispatch, customersApi.listAll)(apiToken).then(response => {
            const customers = []
            for(const customer of response) {
                customers.push({
                    id: customer.ID,
                    name: customer.Name,
                    active: customer.Active,
                })
            }
            customers.sort((a,b) => {
                if(a.active && !b.active) return -1
                if(!a.active && b.active) return 1
                if(a.name.toLowerCase() < b.name.toLowerCase()) return -1
                return 1
            })
            dispatch(setCustomersAction(customers))
            if(onSuccess) onSuccess()
        }).catch(error => {
            dispatch(setErrorAction(error))
            onError(error)
        })
    }
}

export const createCustomer = (apiToken, customer, onError, onSuccess) => {
    return dispatch => {
        const create = async () => {
            const newCustomer = {Name: customer.name, Active: customer.active}
            await ApiCall(dispatch, customersApi.createOne)(apiToken, newCustomer)
        }
        create().then(() => {
            loadCustomers(apiToken, error => console.error(error))(dispatch)
            if(onSuccess) onSuccess()
        }).catch(onError)
    }
}

export const updateCustomer = (apiToken, customer, onError, onSuccess) => {
    return dispatch => {
        const update = async () => {
            const update = {ID: customer.id, Name: customer.name, Active: customer.active}
            await ApiCall(dispatch, customersApi.updateOne)(apiToken, update)
        }
        update().then(() => {
            loadCustomers(apiToken, error => console.error(error))(dispatch)
            if(onSuccess) onSuccess()
        }).catch(onError)
    }
}

// Constants

export const STATUS_UNINITIALIZED = "UNINITIALIZED"
export const STATUS_LOADING = "LOADING"
export const STATUS_ERROR = "ERROR"
export const STATUS_LOADED = "LOADED"

// Action Creators

export const LOAD_CUSTOMERS = "LOAD_CUSTOMERS"
export const SET_ERROR = "SET_LOAD_CUSTOMERS_ERROR"
export const SET_CUSTOMERS = "SET_CUSTOMERS"

const loadCustomersAction = () => {
    return {type: LOAD_CUSTOMERS}
}

const setErrorAction = error => {
    return {type: SET_ERROR, payload: error}
}

const setCustomersAction = customers => {
    return {type: SET_CUSTOMERS, payload: customers}
}

// Selector(s)

export const getCustomersSelector = store => store.customers
export const getCustomersLoadStatus = store => getCustomersSelector(store).loadStatus
export const getCustomersListSelector = store => getCustomersSelector(store).list
export const getCustomersMapSelector = store => getCustomersSelector(store).map
export const getCustomersErrorSelector = store => getCustomersSelector(store).error

export const areCustomersMissingSelector = store => getCustomersLoadStatus(store) === STATUS_UNINITIALIZED
export const isErrorLoadingCustomersSelector = store => getCustomersLoadStatus(store) === STATUS_ERROR
