import React from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
    Paper,
    Typography,
    Divider,
    makeStyles,
    TableRow,
    TablePagination,
    Table,
    TableHead,
    TableFooter,
    TableBody,
    TableCell,
    Breadcrumbs,
} from '@material-ui/core';
import { getApiTokenSelector } from "../../state/Auth.js"
import { getUserFacilitiesMapSelector } from "../../state/UserFacilities.js"
import {
    getCachedFacilityIDSelector,
    getCachedFacilityTagsSelector,
    loadFacility,
} from "../../state/CachedFacility.js"

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    label: {
        fontWeight: "700",
    },
    table: {
        overflowY: "auto",
        [theme.breakpoints.down('xs')]: {
            maxHeight: theme.spacing(40),
            minWidth: theme.spacing(35),
        },
        [theme.breakpoints.up('sm')]: {
            maxHeight: theme.spacing(65),
            minWidth: theme.spacing(65),
        },
    },
}))

const Nav = () => {
    return (
        <Breadcrumbs separator=">" aria-label="breadcrumbs">
            <Link color="inherit" to="/">Home</Link>
        </Breadcrumbs>
    )
}

const Tags = props => {

    const {tags, facilityID} = props

    const classes = useStyles();
    const [offset, setOffset] = React.useState(0)

    const limit = 10
    const count = tags.length
    const pageEnd = offset+limit < count ? offset+limit : count
    const data = tags.slice(offset, pageEnd)

    const paginationRow = (
        <TableRow>
            <TablePagination
             rowsPerPage={limit}
             rowsPerPageOptions={[]}
             count={count}
             page={offset/limit}
             onChangePage={(e,page)=>{setOffset(page*limit)}}
            />
        </TableRow>
    )

    return (
        <div className={classes.table}>
            {count === 0 && <Typography>No Tags</Typography>}
            {count > 0 &&
            <Table>
                {count > limit && <TableHead>{paginationRow}</TableHead>}
                <TableBody>
                    {data.map(tag => (
                        <TableRow key={tag.id}>
                            <TableCell><Link to={`/facilities/${facilityID}/tags/${tag.id}`}>{tag.name}</Link></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                {count > limit && <TableFooter>{paginationRow}</TableFooter>}
            </Table>
            }
        </div>
    )

}

const Facility = props => {

    const {apiToken, facilities, cachedFacilityID, tags, loadFacility} = props
    const {facilityID} = useParams()

    const classes = useStyles();
    const facility = facilities[facilityID]
    const renderTags = facilityID === cachedFacilityID

    React.useEffect(() => {
        if(!renderTags) {
            loadFacility(apiToken, facilityID).catch(error => console.error(error))
        }
    }, [apiToken, renderTags, facilityID, loadFacility])

    if(!facility) {
        return (
            <Paper elevation={6} className={classes.main}>
                <Nav />
                <div className={classes.section}>
                    <Typography variant="h4">Facility</Typography>
                </div>
                <div className={classes.section}>
                    <Typography variant="h6">Loading...</Typography>
                </div>
            </Paper>
        )
    }

    return (
        <Paper elevation={6} className={classes.main}>
            <Nav />
            <div className={classes.section}>
                <Typography variant="h4">{facility.name}</Typography>
            </div>
            <div className={classes.section}>
                <Typography>{facility.description}</Typography>
            </div>
            <Divider />
            <div className={classes.section}>
                <Typography className={classes.label}>Tags</Typography>
                {renderTags &&
                <Tags tags={tags} facilityID={facilityID} />
                }
            </div>
        </Paper>
    )
}

const mapStateToProps = state => {
    const apiToken = getApiTokenSelector(state)
    const facilities = getUserFacilitiesMapSelector(state)
    const cachedFacilityID = getCachedFacilityIDSelector(state)
    const tags = getCachedFacilityTagsSelector(state)
    return {apiToken, facilities, cachedFacilityID, tags}
}

export default connect(mapStateToProps, {loadFacility})(Facility)
