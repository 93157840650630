import React from 'react';
import { getLoggedInUserSelector, logout, logoutAll } from "../../state/Auth"
import { getUserCustomerDetailsSelector } from "../../state/UserCustomer"
import { Paper, Grid, Typography, makeStyles, Divider, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import ChangePasswordForm from "./ChangePasswordForm"

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    infoRow: {
        marginBottom: theme.spacing(1)
    },
    label: {
        fontWeight: "700",
    },
    button: {
      margin: theme.spacing(3, 0, 2),
    },
    buttonRow: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    }
}))

const UserSettings = (props) => {

    const { user, customer, logout, logoutAll } = props

    const classes = useStyles();

    return(
        <Paper elevation={6} className={classes.main}>
            <div className={classes.section}>
                <Typography variant="h4">
                    User Settings
                </Typography>
            </div>
            <Divider />
            <div className={classes.section}>
                <Grid container className={classes.infoRow}>
                    <Grid item xs={6}>
                        <Typography className={classes.label}>Username</Typography>
                    </Grid>
                    <Grid item xs={6}><Typography>{user.username}</Typography></Grid>
                </Grid>
                <Grid container className={classes.infoRow}>
                    <Grid item xs={6}>
                        <Typography className={classes.label}>Organization</Typography>
                    </Grid>
                    <Grid item xs={6}><Typography>{customer ? customer.name : ""}</Typography></Grid>
                </Grid>
            </div>
            <Divider />
            <div className={classes.section}>
                <Typography variant="h6">Change Password</Typography>
                <ChangePasswordForm />
            </div>
            <Divider />
            <div className={classes.section}>
                <Typography variant="h6">Logout</Typography>
                <Grid container>
                    <Grid item xs={6} className={classes.buttonRow}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            id="logout"
                            onClick={logout}
                        >Logout</Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            id="logout-everywhere"
                            onClick={() => {logoutAll(user)}}
                        >Logout All</Button>
                    </Grid>
                </Grid>
            </div>
        </Paper>
    )
}

const mapStateToProps = state => {
    const user = getLoggedInUserSelector(state)
    const customer = getUserCustomerDetailsSelector(state)
    return {user, customer}
}

export default connect(mapStateToProps, {logout, logoutAll})(UserSettings)