import React from 'react';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getApiTokenSelector } from "../../state/Auth"
import { getCustomersMapSelector } from "../../state/Customers"
import {
    getCachedCustomerIDSelector,
    getCachedCustomerFacilitiesMapSelector,
    loadCustomer,
    createUser,
} from "../../state/CachedCustomer"
import {
    Paper,
    makeStyles,
    Breadcrumbs,
    Typography,
    Divider,
    Button,
    TextField,
} from '@material-ui/core';

// Form States
const EDITING = "EDITING"
const CREATING = "CREATING"
const CREATED = "CREATED"

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
}))

const Nav = props => {

    const {customer, facility} = props

    return (
        <Breadcrumbs separator=">" aria-label="breadcrumbs">
            <Link color="inherit" to="/customers">Customers</Link>

            {customer &&
            <Link color="inherit" to={`/customers/${customer.id}`}>{customer.name}</Link>
            }

            {facility &&
            <Link
                color="inherit"
                to={`/customers/${customer.id}/facilities/${facility.id}`}>
                    {facility.name}
            </Link>
            }
        </Breadcrumbs>
    )
}

const NewUser = (props) => {

    // inputs
    const {
        apiToken,
        customers, cachedCustomerID, loadCustomer,
        facilities, createUser,
    } = props
    const {customerID, facilityID} = useParams()
    const customer = customers[customerID]
    const facility = facilities[facilityID]

    // state
    const classes = useStyles();
    const [username, setUsername] = React.useState("")
    const [error, setError] = React.useState("")
    const [formState, setFormState] = React.useState(EDITING)
    const [message, setMessage] = React.useState("")
    const disableSubmit = (formState !== EDITING || username.length === 0)

    React.useEffect(() => {
        if(customerID !== cachedCustomerID) {
            loadCustomer(apiToken, customerID, error => console.error(error))
        }
    }, [apiToken, customerID, cachedCustomerID, loadCustomer])

    // event handlers
    const onInputChange = setter => e => {
        if(error.length !== 0) {
            setError("")
        }
        setter(e.target.value)
    }

    const onSubmit = () => {
        createUser(
            apiToken,
            customerID,
            facility.id,
            username,
            error => {
                if(error?.message === "Unauthorized") {
                    // do nothing. user will get logged out
                    return
                }
                setFormState(EDITING)
                setError(error.message)
            },
            () => {
                setFormState(CREATED)
                setMessage("User created successfully!")
            },
        )
    }

    // JSX
    if((customerID !== customer?.id) || (facilityID !== facility?.id)) {
        return (
            <Paper elevation={6} className={classes.main}>
                <Nav customer={customer} facility={facility} />
                <div className={classes.section}>
                    <Typography variant="h4">New User</Typography>
                </div>
                <div className={classes.section}>
                    <Typography variant="h6">Loading...</Typography>
                </div>
            </Paper>
        )
    }

    return (
    <Paper elevation={6} className={classes.main}>
        <Nav customer={customer} facility={facility} />
        <div className={classes.section}>
            <Typography variant="h4">{facility.name}</Typography>
        </div>
        <Divider />
        <div className={classes.section}>
            <Typography variant="h6">New User</Typography>
            <form
                onSubmit={e=>{
                    e.preventDefault();
                    setFormState(CREATING)
                    onSubmit()
                }}
                noValidate
            >
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoComplete="username"
                    value={username}
                    error={error.length === 0 ? false : true}
                    helperText={error || message}
                    onChange={onInputChange(setUsername)}
                    disabled={formState === EDITING ? false : true}
                    autoFocus
                    />
                {formState !== CREATED &&
                <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={disableSubmit}
                    className={classes.submit}
                    id="create-user"
                >Create</Button>
                }
                {formState === CREATED &&
                <Button
                    fullWidth
                    type="button"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    id="create-another"
                    onClick={() => {
                        setUsername("")
                        setError("")
                        setMessage("")
                        setFormState(EDITING)
                    }}
                >Reset Form</Button>
                }
            </form>
        </div>
    </Paper>
    )

}

const mapStateToProps = state => {
    const apiToken = getApiTokenSelector(state)
    const customers = getCustomersMapSelector(state)
    const cachedCustomerID = getCachedCustomerIDSelector(state)
    const facilities = getCachedCustomerFacilitiesMapSelector(state)
    return {apiToken, customers, cachedCustomerID, facilities}
}

export default connect(mapStateToProps, {loadCustomer, createUser})(NewUser)