import React from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { 
    Paper,
    makeStyles,
    Breadcrumbs,
    Typography,
    Grid,
    Divider,
    Button,
    Dialog,
} from '@material-ui/core';
import { getApiTokenSelector } from "../../state/Auth.js"
import { getCustomersMapSelector } from "../../state/Customers"
import {
    getCachedCustomerIDSelector,
    getCachedCustomerFacilitiesMapSelector,
    loadCustomer,
} from "../../state/CachedCustomer"
import { loadFacility } from "../../state/CachedFacility"
import AppConfiguration from "../../config/Config.json"
import ApiCall from "../../api/ApiCall.js"
import TagsApi from "../../api/Tags.js"

const tagsURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Tags
const tagsApi = new TagsApi(tagsURL)

const loadTag = (apiToken, id, onError, onSuccess) => {
    return dispatch => {
        ApiCall(dispatch, tagsApi.getOne)(apiToken, id)
            .then(onSuccess)
            .catch(onError)
    }
}

const deleteTag = (apiToken, id, onError, onSuccess) => {
    return dispatch => {
        ApiCall(dispatch, tagsApi.deleteOne)(apiToken, id)
            .then(onSuccess)
            .catch(onError)
    }
}

const useStyles = makeStyles(theme => ({
    main: {
        padding: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
    },
    section: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    infoRow: {
        marginBottom: theme.spacing(1)
    },
    label: {
        fontWeight: "700",
    },
    printButton: {
      margin: theme.spacing(2, 0, 2),
    },
    deleteButton: {
      margin: theme.spacing(0, 0, 2),
    },
}))

const Nav = props => {

    const {customer, facility} = props

    return (
        <Breadcrumbs separator=">" aria-label="breadcrumbs">
            <Link color="inherit" to="/customers">Customers</Link>

            {customer &&
            <Link color="inherit" to={`/customers/${customer.id}`}>{customer.name}</Link>
            }

            {facility &&
            <Link
                color="inherit"
                to={`/customers/${customer.id}/facilities/${facility.id}`}>
                    {facility.name}
            </Link>
            }
        </Breadcrumbs>
    )
}

const DeleteConfirmation = props => {

    const {tag, facility, open, onConfirm, onCancel} = props
    const classes = useStyles();

    return(
    <Dialog onClose={onCancel} aria-labelledby="confirm-delete-dialog" open={open}>
        <div className={classes.main}>
            <div className={classes.section}>
                <Typography variant="h6">Confirm Tag Delete</Typography>
            </div>
            <div className={classes.section}>
                <Typography>Are you sure you want to delete '{tag?.Name}' tag from '{facility?.name}'' facility</Typography>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.printButton}
                    id="confirm-delete-button"
                    onClick={onConfirm}
                >Yes</Button>
                <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.deleteButton}
                    id="confirm-delete-button"
                    onClick={onCancel}
                >No</Button>
            </div>
        </div>
    </Dialog>
    )
}

const Tag = props => {

    const { 
        apiToken,
        customers, cachedCustomerID, loadCustomer,
        facilities, loadFacility, 
        loadTag, deleteTag,
    } = props
    const {customerID, facilityID, tagID} = useParams()
    const customer = customers[customerID]
    const facility = facilities[facilityID]

    const classes = useStyles();
    const [tag, setTag] = React.useState({})
    const [error, setError] = React.useState("")
    const [message, setMessage] = React.useState("")
    const [confirmDelete, setConfirmDelete] = React.useState(false)
    const [disabled, disable] = React.useState(false)

    React.useEffect(() => {
        if(customerID !== cachedCustomerID) {
            loadCustomer(apiToken, customerID, error => console.error(error))
        }
    }, [apiToken, customerID, cachedCustomerID, loadCustomer])

    React.useEffect(() => {
        loadTag(apiToken, tagID, error => {
            console.error(error)
        }, response => {
            setTag(response)
        })
    }, [apiToken, tagID, loadTag, setTag])

    const onPrint = () => {console.log("Printed")}
    const onDelete = () => {
        disable(true)
        deleteTag(apiToken, tag.ID, error => {
            if(error?.message === "Unauthorized") {
                // do nothing. user will get logged out
                return
            }
            setMessage("")
            setError(error.message)
            disable(false)
        }, () => {
            setError("")
            setMessage("Tag Deleted")
            loadFacility(apiToken, facility.id, error => console.error(error))
        })
    }

    if(tagID !== tag?.ShortID) {
        return (
            <Paper elevation={6} className={classes.main}>
                <Nav customer={customer} facility={facility} />
                <div className={classes.section}>
                    <Typography variant="h4">Tag</Typography>
                </div>
                <div className={classes.section}>
                    <Typography variant="h6">Loading...</Typography>
                </div>
            </Paper>
        )
    }

    return (
    <Paper elevation={6} className={classes.main}>
        <Nav customer={customer} facility={facility} />
        <div className={classes.section}>
            <Typography variant="h4">{tag.Name}</Typography>
        </div>
        <Divider />
        <div className={classes.section}>
            <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.printButton}
                id="print-tag"
                disabled={disabled}
                onClick={onPrint}
            >Print</Button>
            <Button
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.deleteButton}
                id="delete-tag"
                disabled={disabled}
                onClick={() => setConfirmDelete(true)}
            >Delete</Button>
            <DeleteConfirmation
                tag={tag}
                facility={facility}
                open={confirmDelete}
                onConfirm={() => { setConfirmDelete(false); onDelete(); }}
                onCancel={() => { setConfirmDelete(false) }}
            />
            <span>{error || message}</span>
        </div>
        <Divider />
        <div className={classes.section}>
            <Typography variant="h6">Exercise</Typography>
            <Grid container className={classes.infoRow}>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Name</Typography>
                </Grid>
                <Grid item xs={6}><Typography>{tag.Exercise.Name}</Typography></Grid>
            </Grid>
            <Grid container className={classes.infoRow}>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Description</Typography>
                </Grid>
                <Grid item xs={6}><Typography>{tag.Exercise.Description}</Typography></Grid>
            </Grid>
            <Grid container className={classes.infoRow}>
                <Grid item xs={6}>
                    <Typography className={classes.label}>Type</Typography>
                </Grid>
                <Grid item xs={6}><Typography>{tag.Exercise.Type}</Typography></Grid>
            </Grid>
        </div>
        <Divider />
        <div className={classes.section}>
            <Typography variant="h6">Media</Typography>
            {tag.Exercise.Media.length === 1 &&
            <a href={`https://www.youtube.com/watch?v=${tag.Exercise.Media[0].Data}`}>YouTube</a>
            }
            {tag.Exercise.Media.length !== 1 && <Typography>No Media</Typography>}
        </div>
    </Paper>
    )
}

const mapStateToProps = state => {
    const apiToken = getApiTokenSelector(state)
    const customers = getCustomersMapSelector(state)
    const cachedCustomerID = getCachedCustomerIDSelector(state)
    const facilities = getCachedCustomerFacilitiesMapSelector(state)
    return {apiToken, customers, cachedCustomerID, facilities}
}

export default connect(mapStateToProps, {loadCustomer, loadFacility, loadTag, deleteTag})(Tag)
