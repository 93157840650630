import {
    LOAD_USER_FACILITIES,
    SET_ERROR,
    SET_USER_FACILITIES,
    STATUS_UNINITIALIZED,
    STATUS_LOADING,
    STATUS_LOADED,
    STATUS_ERROR,
} from "../UserFacilities";
import { LOGOUT } from "../Auth";

const initialState = { loadStatus : STATUS_UNINITIALIZED, list: [], map: {} }

export default function(state=initialState, action) {
    switch(action.type) {
        case LOAD_USER_FACILITIES: {
            return { ...initialState, loadStatus: STATUS_LOADING }
        }
        case SET_ERROR : {
            return { ...initialState, loadStatus : STATUS_ERROR }
        }
        case SET_USER_FACILITIES : {
            const map = {}
            action.payload.forEach(facility => {
                map[facility.id] = facility
            })
            return { loadStatus : STATUS_LOADED, list : action.payload, map: map  }
        }
        case LOGOUT : {
            return initialState
        }
        default:
            return state
    }
}
