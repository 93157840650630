export default class UsersApi {

    constructor(apiUrl) {
         this.apiUrl = apiUrl;
    }

    listAll = async (apiToken, customerID) => {

        if(!apiToken || !customerID) {
            throw Error("apiToken and customerID required to list users")
        }

        return await fetch(
            `${this.apiUrl}?customerID=${customerID}`,
            {
                headers: new Headers({
                    'Authorization': `Bearer ${apiToken}`
                })
            }
        )
    }

    createOne = async(apiToken, newUser) => {

        if(!apiToken || !newUser?.Username || !newUser?.Password || !newUser?.CustomerID ) {
            throw Error("apiToken and user details required to create user")
        }

        return await fetch(
            `${this.apiUrl}`,
            {
                method: 'post',
                body: JSON.stringify(newUser),
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )
    }

    changePassword = async (apiToken, username, newPassword) => {

        if(!username || !apiToken) {
            throw Error("username and apiToken required to delete token")
        }

        return await fetch(
            `${this.apiUrl}/${username}/passwordChange`,
            {
                method: 'put',
                body: JSON.stringify(newPassword),
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                    'Content-Type': "application/json",
                })
            }
        )
    }

    listAccessibleFacilities = async(apiToken, username) => {

        if(!apiToken || !username) {
            throw Error("username and apiToken required to list accessible facilities")
        }

        return await fetch(
            `${this.apiUrl}/${username}/facilityAccesses`,
            {
                headers: new Headers({
                    'Authorization': 'Bearer '+apiToken,
                })
            }
        )

    }

}