import AppConfiguration from "../config/Config.json"
import ApiCall from "../api/ApiCall.js"
import FacilitiesApi from "../api/Facilities"
import UsersApi from "../api/Users"
import UsernamesApi from "../api/Usernames"

const facilitiesURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Facilities
const facilitiesApi = new FacilitiesApi(facilitiesURL)

const usersURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Users
const usersApi = new UsersApi(usersURL)

const usernamesURL = AppConfiguration.Api.BaseURL + "/" + AppConfiguration.Api.Usernames
const usernamesApi = new UsernamesApi(usernamesURL)

// Actions

const _isUserNameAvailable = async (dispatch, username) => {
    await ApiCall(dispatch, usernamesApi.isAvailable)(username)
}

const _createUser = async (dispatch, apiToken, customerID, facilityID, username) => {
    const newUser = {
        Username: username,
        Password: "Ch@ngeM3",
        CustomerID: customerID,
        FacilitiesIDs: [facilityID],
    }
    await ApiCall(dispatch, usersApi.createOne)(apiToken, newUser)
}

export const loadCustomer = (apiToken, customerID, onError, onSuccess) => {
    return dispatch => {
        dispatch(loadDataForCustomer(customerID))
        const doAsync = async () => {

            // gather API data
            const rawFacilities = await ApiCall(dispatch, facilitiesApi.listAll)(apiToken, customerID)
            const rawUsers = await ApiCall(dispatch, usersApi.listAll)(apiToken, customerID)

            // process facilities
            const list = []
            const map = {}
            rawFacilities.forEach(raw => {
                const facility = {id : raw.ID, name: raw.Name, description: raw.Description, users: []}
                list.push(facility)
                map[facility.id] = facility
            })

            // process users
            rawUsers.forEach(raw => {
                raw.FacilitiesIDs.forEach(facilityID => {
                    map[facilityID].users.push(raw.Username)
                })
            })

            dispatch(setDataForCustomer({list, map}))

        }

        doAsync().then(() => {
            if(onSuccess) onSuccess()
        }).catch(error => {
            dispatch(setErrorAction(error))
            onError(error)
        })
    }
}

export const createFacility = (apiToken, customerID, facility, username, onError, onSuccess) => {
    return dispatch => {
        const create = async () => {
            await _isUserNameAvailable(dispatch, username)
            const newFacility = {Name: facility.name, Description: facility.description}
            const created = await ApiCall(dispatch, facilitiesApi.createOne)(apiToken, customerID, newFacility)
            await _createUser(dispatch, apiToken, customerID, created.ID, username)
        }
        create().then(() => {
            loadCustomer(apiToken, customerID, error => console.error(error))(dispatch)
            if(onSuccess) onSuccess()
        }).catch(onError)
    }
}

export const updateFacility = (apiToken, customerID, facility, onError, onSuccess) => {
    return dispatch => {
        const update = async () => {
            const toUpdate = {ID: facility.id, Name: facility.name, Description: facility.description}
            await ApiCall(dispatch, facilitiesApi.updateOne)(apiToken, toUpdate)
        }
        update().then(() => {
            loadCustomer(apiToken, customerID, error => console.error(error))(dispatch)
            if(onSuccess) onSuccess()
        }).catch(onError)
    }
}

export const createUser = (apiToken, customerID, facilityID, username, onError, onSuccess) => {
    return dispatch => {
        const create = async () => {
            await _isUserNameAvailable(dispatch, username)
            await _createUser(dispatch, apiToken, customerID, facilityID, username)
        }
        create().then(() => {
            loadCustomer(apiToken, customerID, error => console.error(error))(dispatch)
            if(onSuccess) onSuccess()
        }).catch(onError)
    }
}

// Constants

export const STATUS_UNINITIALIZED = "UNINITIALIZED"
export const STATUS_LOADING = "LOADING"
export const STATUS_ERROR = "ERROR"
export const STATUS_LOADED = "LOADED"

// Action Creators

export const LOAD_DATA_FOR_CUSTOMER = "LOAD_DATA_FOR_CUSTOMER"
export const SET_ERROR = "SET_LOAD_DATA_FOR_CUSTOMER_ERROR"
export const SET_DATA_FOR_CUSTOMER = "SET_DATA_FOR_CUSTOMER"

const loadDataForCustomer = customerID => {
    return {type: LOAD_DATA_FOR_CUSTOMER, payload: customerID}
}

const setErrorAction = error => {
    return {type: SET_ERROR, payload: error}
}

const setDataForCustomer = facilities => {
    return {type: SET_DATA_FOR_CUSTOMER, payload: facilities}
}

// Selector(s)

export const getCachedCustomerSelector = store => store.cachedCustomer
export const getCachedCustomerIDSelector = store => getCachedCustomerSelector(store).id
export const getCachedCustomerLoadStatusSelector = store => getCachedCustomerSelector(store).loadStatus
export const getCachedCustomerFacilitiesSelector = store => getCachedCustomerSelector(store).facilities

export const getCachedCustomerFacilitiesListSelector = store => {
    const facilities = getCachedCustomerFacilitiesSelector(store)
    return facilities ? facilities.list : []
}
export const getCachedCustomerFacilitiesMapSelector = store => {
    const facilities = getCachedCustomerFacilitiesSelector(store)
    return facilities ? facilities.map : {}
}
