import { isUserLoggedInSelector, getLoggedInUserSelector } from "../Auth"
import { areUserFacilitiesMissingSelector, loadUserFacilities } from "../UserFacilities"

const shouldRun = state => {
    if(!isUserLoggedInSelector(state)) return false
    const user = getLoggedInUserSelector(state)
    return (!user.isSuperAdmin) && areUserFacilitiesMissingSelector(state)
}

const run = (state, dispatch) => {
    const user = getLoggedInUserSelector(state)
    const {apiToken, username} = user
    const loadUserFacilitiesWithDispatch = loadUserFacilities(
        apiToken, username, error => console.error(error),
    )
    loadUserFacilitiesWithDispatch(dispatch)
}

export default {shouldRun, run}