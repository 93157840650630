import {
    LOAD_CUSTOMERS,
    SET_ERROR,
    SET_CUSTOMERS,
    STATUS_UNINITIALIZED,
    STATUS_LOADING,
    STATUS_LOADED,
    STATUS_ERROR,
} from "../Customers";
import { LOGOUT } from "../Auth";

const initialState = { loadStatus : STATUS_UNINITIALIZED, list : [], map : {} }

export default function(state=initialState, action) {
    switch(action.type) {
        case LOAD_CUSTOMERS: {
            return { ...initialState, loadStatus: STATUS_LOADING }
        }
        case SET_ERROR : {
            return { ...initialState, loadStatus : STATUS_ERROR, error : action.payload }
        }
        case SET_CUSTOMERS : {
            const map = {}
            action.payload.forEach(customer => {
                map[customer.id] = customer
            })
            return { loadStatus : STATUS_LOADED, list : action.payload, map : map }
        }
        case LOGOUT : {
            return initialState
        }
        default:
            return state
    }
}
