import {
    STATUS_UNINITIALIZED,
    STATUS_LOADING,
    STATUS_ERROR,
    STATUS_LOADED,
    LOAD_TAGS_FOR_FACILITY,
    SET_ERROR,
    SET_TAGS_FOR_FACILITY,
} from "../CachedFacility";
import { LOGOUT } from "../Auth";

const initialState = {loadStatus : STATUS_UNINITIALIZED, tags: []}

export default function(state=initialState, action) {
    switch(action.type) {
        case LOAD_TAGS_FOR_FACILITY: {
            return {...initialState, loadStatus: STATUS_LOADING, id: action.payload}
        }
        case SET_ERROR : {
            return {...state, loadStatus: STATUS_ERROR, error: action.payload}
        }
        case SET_TAGS_FOR_FACILITY: {
            return {...state, loadStatus: STATUS_LOADED, tags: action.payload}
        }
        case LOGOUT : {
            return initialState
        }
        default:
            return state
    }
}